import React, {useEffect, useMemo} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import IntroScreen from "./screens/intro/IntroScreen";
import LoginScreen from "./screens/login/LoginScreen";
import MainScreen from "./screens/main/MainScreen";
import {MainViewModel} from "./screens/main/MainViewModel";
import {CatalogViewModel} from "./screens/catalog/CatalogViewModel";
import {OffersViewModel} from "./screens/offer/list/OffersViewModel";
import {ProfileViewModel} from "./screens/profile/ProfileViewModel";
import {AppEvent, AppViewModel} from "./AppViewModel";
import {reaction} from "mobx";
import {observer} from "mobx-react";
import {ProductsViewModel} from './screens/products/list/ProductsViewModel'
import {CheckAuth} from "./data/utils/CheckAuth";
import {OfferFlowScreen} from "./screens/offer/details/OfferFlowScreen";
import {ProductFlowScreen} from "./screens/products/details/ProductFlowScreen";
import {ToastContainer} from "react-toastify";
import {FiltersFlowScreen} from "./screens/filter/FiltersFlowScreen";
import {StatisticViewModel} from "./screens/statistic/StatisticViewModel";
import {RangesScreen} from "./screens/statistic/range/RangesScreen";
import StatisticDetailsScreen from "./screens/statistic/details/StatisticDetailsScreen";
import TopScreen from "./screens/statistic/top/TopSellersScreen";
import RegisterFlowScreen from "./screens/register/RegisterFlowScreen";
import ResetFlowScreen from "./screens/reset/ResetFlowScreen";
import ChangePasswordScreen from "./screens/password/ChangePasswordScreen";
import {QueryClient, QueryClientProvider} from 'react-query';

const queryClient: QueryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            staleTime: 1000 * 60 * 5
        }
    }
});

function App() {
    const viewModel = useMemo(() => new AppViewModel(), [])
    const mainViewModel = useMemo(() => new MainViewModel(), [])
    const catalogViewModel = useMemo(() => new CatalogViewModel(), [])
    const offersViewModel = useMemo(() => new OffersViewModel(), [])
    const productsViewModel = useMemo(() => new ProductsViewModel(), [])
    const profileViewModel = useMemo(() => new ProfileViewModel(), [])
    const statisticViewModel = useMemo(() => new StatisticViewModel(), [])

    useEffect(
        () => reaction(() => viewModel.event,
            event => {
                if (event === AppEvent.Login || event === AppEvent.Logout) {
                    window.location.replace(window.location.origin)
                }
            }),
        []
    )

    return (
        <div>
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/">
                            <Route index element={
                                <CheckAuth>
                                    <MainScreen
                                        viewModel={mainViewModel}
                                        catalogViewModel={catalogViewModel}
                                        offersViewModel={offersViewModel}
                                        productsViewModel={productsViewModel}
                                        profileViewModel={profileViewModel}
                                        statisticViewModel={statisticViewModel}
                                    />
                                </CheckAuth>
                            }/>
                            <Route path='filters/*' element={
                                <CheckAuth>
                                    <FiltersFlowScreen productsViewModel={productsViewModel}/>
                                </CheckAuth>
                            }/>
                            <Route path="offers/:offerId/*" element={
                                <CheckAuth>
                                    <OfferFlowScreen/>
                                </CheckAuth>
                            }/>
                            <Route path="products/:productId/*" element={
                                <CheckAuth>
                                    <ProductFlowScreen/>
                                </CheckAuth>
                            }/>
                            <Route path="ranges" element={
                                <CheckAuth>
                                    <RangesScreen viewModel={statisticViewModel}/>
                                </CheckAuth>
                            }/>
                            <Route path="top" element={
                                <CheckAuth>
                                    <TopScreen/>
                                </CheckAuth>
                            }/>
                            <Route path="details/:offerId" element={
                                <CheckAuth>
                                    <StatisticDetailsScreen/>
                                </CheckAuth>
                            }/>
                        </Route>
                        <Route path="/password" element={
                            <CheckAuth>
                                <ChangePasswordScreen/>
                            </CheckAuth>
                        }/>
                        <Route path="/intro" element={<IntroScreen/>}/>
                        <Route path="/login" element={<LoginScreen/>}/>
                        <Route path="/register/*" element={<RegisterFlowScreen/>}/>
                        <Route path="/reset/*" element={<ResetFlowScreen/>}/>
                    </Routes>
                </BrowserRouter>
                <ToastContainer/>
            </QueryClientProvider>
        </div>
    );
}

export default observer(App);
