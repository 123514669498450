import {makeAutoObservable} from "mobx";
import {CatalogTab} from "../../components/tabs/CatalogTabs";

interface State {
    isOffersEnabled: boolean,
    isProductsEnabled: boolean,
    selectedTab: CatalogTab
}

export class CatalogViewModel {
    private isOffersEnabled: boolean = true
    private isProductsEnabled: boolean = true
    private selectedTab: CatalogTab = CatalogTab.OFFERS

    constructor() {
        makeAutoObservable(this)
    }

    get state(): State {
        return {
            isOffersEnabled: this.isOffersEnabled,
            isProductsEnabled: this.isProductsEnabled,
            selectedTab: this.selectedTab
        }
    }

    selectTab(tab: CatalogTab) {
        this.selectedTab = tab
    }
}
