import { Compensation } from '../../data/network/dto/OfferDto'
import styles from "./compensation_item.module.css"
import getCost from "../../helpers/getCost";

interface Props {
    compensation: Compensation
}

export const CompensationItem = ({compensation}:Props) => {

    const getPrice = (): string => {
        if (compensation.rev > 0) {
            return `${compensation.rev} ${compensation.rev_currency}`
        } else {
            return getCost(compensation.cost)
        }
    }

    return (<div className={styles.container}>
        <p className={styles.price}>{getPrice()}</p>
        <p className={styles.title}>{compensation.description}</p>
    </div>)
}
