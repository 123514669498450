import {repository} from "../../../../data/repo/Repository";

export class PlainPromocodeViewModel {
    private codeId = ''

    constructor(codeId: string) {
        this.codeId = codeId
        this.markAsShown()
    }

    private markAsShown() {
        repository.markPromocodeAsShown(this.codeId)
            .catch((e) => {
                console.log(e)
            })
    }
}
