import {observer} from "mobx-react"
import {useEffect} from "react"
import {useNavigate} from "react-router-dom"
import ButtonAccent from "../../../components/button/ButtonAccent"
import {Loader} from "../../../components/loader/Loader"
import {OfferNavBar} from "../../../components/navbar/OfferNavBar"
import {CompensationItem} from "../../../components/offer/CompensationItem"
import {FlowType} from "../../../data/entity/FlowType"
import {OfferDetailsViewModel} from "./OfferDetailsViewModel"
import styles from "./offer_details.module.css"
import {share, showErrorSnack} from "../../RootScreen";
import {reaction} from "mobx";
import {AsyncState} from "../../../data/entity/Async";
import {InvisibleSmartCaptcha} from "@yandex/smart-captcha";

interface Props {
    viewModel: OfferDetailsViewModel
}
const OfferDetailsScreen = ({viewModel}: Props) => {

    let navigate = useNavigate();

    useEffect(() => {
        viewModel.getFollowInfo()
        return function cleanup() {
            viewModel.deInit()
        }
    }, [])

    useEffect(() => reaction( () => viewModel.plainPromocode,
        promocode => {
            if (promocode.state === AsyncState.SUCCESS) {
                navigate('plain', {
                    state:{
                        code: promocode.data?.code,
                        id: promocode.data?.id
                    }
                })
            } else if (promocode.state === AsyncState.FAIL) {
                showErrorSnack(promocode.error)
            }
        }), []);

    useEffect(() => reaction( () => viewModel.order,
        order => {
            if (order.state === AsyncState.SUCCESS) {
                if (viewModel.state.offer?.flow === FlowType.PAY_QR_PROMOCODE) {
                    navigate(`promocode/${order.data?.id}`)
                } else if (viewModel.state.offer?.flow === FlowType.SELL_PRODUCT_EMAIL) {
                    navigate(`promocode_sell/${order.data?.id}`)
                } else if (viewModel.state.offer?.flow === FlowType.SHARE_PRODUCT_EMAIL) {
                    navigate(`promocode_share/${order.data?.id}`)
                }
            } else if (order.state === AsyncState.FAIL) {
                showErrorSnack(order.error)
            }
        }), []);

    let navbar = () => {
        if (viewModel.state.offer === null) {
            return <div/>
        } else {
            return <OfferNavBar
                style={{position: 'fixed', zIndex: '1'}}
                title={viewModel.state.offer.title}
                logo={viewModel.state.offer.image}
                onBackClick={() => {
                    (
                        viewModel.state.clickId === null ||
                        window.confirm(`Вы собираетесь выйти из оффера до передачи промокода клиенту`)
                    ) && navigate(-1)
                }}/>
        }
    }

    let qrBlock = () => {
        let sharingAvailable = viewModel.state.offer?.sharing_available ?? true
        if (sharingAvailable) {
            return (
                <div className={styles.qrContainer}>
                    <p className={styles.qrTitle}>Отсканируй QR-код</p>
                    <p className={styles.qrTitle}>Для установки приложения</p>
                    {viewModel.state.link != null && <img className={styles.qrCode} src={viewModel.state.link.qrcode}/>}
                </div>
            )
        } else {
            let logo = viewModel.state.offer?.image
            return (
                <div className={styles.qrContainer}>
                    <p className={styles.qrTitle}>Следуй инструкции ниже</p>
                    {logo ? <img className={styles.qrLogo} src={logo}/> : <div className={styles.qrLogo}/>}
                </div>
            )
        }
    }

    let shareButton = () => {
        if (viewModel.state.offer?.flow !== FlowType.PRODUCT_KEY
            && viewModel.state.offer?.sharing_available === true) {
            return <ButtonAccent
                title={'Поделиться'}
                onClick={() => {
                    share(viewModel.state.link?.url)
                }}
                isLoading={false}
                isEnabled={true}
            />
        }
    }

    let sellPromocodeButton = () => {
        if (viewModel.state.offer?.flow === FlowType.PAY_QR_PROMOCODE
            || viewModel.state.offer?.flow === FlowType.SELL_PRODUCT_EMAIL) {
            return <ButtonAccent
                buttonStyle={{marginTop: 16}}
                title={'Продать промокод'}
                onClick={() => {
                    viewModel.createPayment()
                }}
                isLoading={viewModel.state.order.state === AsyncState.LOADING}
                isEnabled={viewModel.state.clickId !== null || viewModel.state.offer?.flow === FlowType.SELL_PRODUCT_EMAIL}
            />
        }
    }

    let plainPromocodeButton = () => {
        if (viewModel.state.offer?.flow === FlowType.PLAIN_PROMOCODE
            || viewModel.state.offer?.flow === FlowType.SHARE_PRODUCT_EMAIL) {
            return <ButtonAccent
                buttonStyle={{marginTop: 16}}
                title={'Бесплатный промокод'}
                onClick={() => {
                    if (viewModel.state.offer?.flow === FlowType.PLAIN_PROMOCODE) {
                        viewModel.getPromocode()
                    } else if (viewModel.state.offer?.flow === FlowType.SHARE_PRODUCT_EMAIL) {
                        viewModel.createPayment()
                    }
                }}
                isLoading={viewModel.state.plainPromocode.state === AsyncState.LOADING}
                isEnabled={viewModel.state.clickId !== null || viewModel.state.offer?.flow === FlowType.SHARE_PRODUCT_EMAIL}
            />
        }
    }

    let webSiteButton = () => {
        if (viewModel.state.offer?.web_url) {
            return <ButtonAccent
                buttonStyle={{marginTop: 16}}
                title={'На сайт'}
                onClick={() => {
                    //todo open web site
                }}
                isLoading={false}
                isEnabled={true}
            />
        }
    }

    let buyButton = () => {
        if (viewModel.state.offer?.flow === FlowType.PRODUCT_KEY) {
            return <ButtonAccent
                buttonStyle={{marginTop: 16}}
                title={'Продать'}
                onClick={() => {
                    //todo open web site
                }}
                isLoading={false}
                isEnabled={true}
            />
        }
    }

    if (viewModel.state.offer === null) {
        return <Loader />;
    }

    return (
        <div className={styles.pageRoot}>
            {navbar()}
            <div className={styles.contentContainer}>
                {qrBlock()}
                <div className={styles.buttonsContainer}>
                    {shareButton()}
                    {sellPromocodeButton()}
                    {plainPromocodeButton()}
                    {webSiteButton()}
                    {buyButton()}
                    {viewModel.state.offer?.compensations?.map((compensation) => {
                        return <CompensationItem key={compensation.rev} compensation={compensation}/>
                    })}
                    {(
                        viewModel.state.offer?.flow === FlowType.PLAIN_PROMOCODE &&
                        !!viewModel.state.offer?.is_captcha_required
                    ) && (
                        <InvisibleSmartCaptcha
                            sitekey={`ysc1_r7OiXcJUGt4SbcpuYi7S7uWKG40l2wKxbDHMHOQKcdb745a8`}
                            onSuccess={(token: string) => viewModel.setCaptchaToken(token)}
                            visible={true}
                        />
                    )}
                    <p style={{whiteSpace: 'pre-wrap', paddingBottom: '20px'}}>{viewModel.state.offer?.description}</p>
                </div>
            </div>
        </div>
    )
}

export default observer(OfferDetailsScreen)
