import {observer} from 'mobx-react'
import {useEffect, useState} from 'react'
import filterIcon from '../../images/icons/ic_24_equalizer.svg'
import {ProductsViewModel} from '../../screens/products/list/ProductsViewModel'
import {useDebounce} from '../base/UseDebounce'
import {CollectionView, Orientation} from '../collection/VericalCollection'
import {SearchInput} from '../input/SearchInput'
import {TagItem} from '../tag/TagItem'
import styles from './products_header_block.module.css'
import {getActiveFilters, hasActiveFilters} from "../../data/entity/FilterInfo";

interface Props {
    viewModel: ProductsViewModel,
    onClickFilter: () => void
}

const ProductsHeaderBlock = ({viewModel, onClickFilter}: Props) => {
    let [query, setQuery] = useState<string | null>(viewModel.state.query)
    let debouncedValue = useDebounce<string | null>(query, 300)

    useEffect(() => {
        viewModel.search(debouncedValue)
    }, [debouncedValue])

    return (
        <div className={styles.rootContainer}>
            {hasActiveFilters(viewModel.state.filterInfo)
                && <p className={styles.filterCounter}>{getActiveFilters(viewModel.state.filterInfo)}</p>}
            <div className={styles.container}>
                <div className={styles.searchBlock}>
                    <SearchInput
                        initQuery={viewModel.state.query ?? ''}
                        onChange={text => viewModel.search(text)}
                        containerStyle={{paddingLeft: '16px', paddingRight: '6px', width: '100%'}}
                    />
                    <div className={styles.filterButton} onClick={onClickFilter}>
                        <img className={styles.iconFilter} src={filterIcon}/>
                    </div>
                </div>
                <CollectionView
                    id={'tags_list'}
                    orientation={Orientation.HORIZONTAL}
                    items={viewModel.state.tags.tags}
                    mapItem={item => (
                        <TagItem
                            key={item.title}
                            tag={item}
                            isSelectedTag={
                                item.title === viewModel.state.tags.selectedTag?.title
                            }
                            onTagClick={tag => viewModel.selectTag(tag)}
                        />
                    )}
                    containerStyle={{
                        paddingLeft: '14px',
                        paddingRight: '14px',
                        paddingTop: '8px',
                    }}
                />
            </div>
        </div>
    )
}

export default observer(ProductsHeaderBlock)
