import parse from 'html-react-parser'
import {reaction} from 'mobx'
import {observer} from 'mobx-react'
import {useEffect, useMemo, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import ButtonAccent from '../../../components/button/ButtonAccent'
import {Loader} from '../../../components/loader/Loader'
import {OfferNavBar} from '../../../components/navbar/OfferNavBar'
import {AsyncState} from '../../../data/entity/Async'
import {getStringWithCents} from '../../../data/network/dto/MoneyDto'
import icHide from '../../../images/icons/ic_20_hide_balance.svg'
import ic_back from "../../../images/icons/ic_24_back_white.svg"
import {ProductDetailsViewModel} from './ProductDetailsViewModel'
import styles from './product_details.module.css'
import {share, showErrorSnack} from '../../RootScreen'

interface Props {
    viewModel: ProductDetailsViewModel
}
const ProductDetailsScreen = ({viewModel}: Props) => {

    let navigate = useNavigate()
    const [isShowNavbar, setShowNavbar] = useState(false)

    useEffect(
        () => reaction(() => viewModel.referralLink,
            (event): any => {
                if (event.state === AsyncState.SUCCESS) {
                    share(event.data!)
                } else if (event.state === AsyncState.FAIL) {
                    showErrorSnack(event.error)
                }
            }),
        []
    )

    useEffect(
        () => reaction(() => viewModel.productOrder,
                (event): any => {
                    if (event.state === AsyncState.SUCCESS) {
                        navigate(`order/${event.data?.id}`)
                    } else if (event.state === AsyncState.FAIL) {
                        showErrorSnack(event.error)
                    }
                }),
        []
    )

    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const {scrollTop} = event.currentTarget
        if (scrollTop >= 400 && !isShowNavbar) {
            setShowNavbar(true)
        } else if (scrollTop < 400 && isShowNavbar) {
            setShowNavbar(false)
        }
    }

    let sellButton = () => {
        return <ButtonAccent
            buttonStyle={{marginTop: 16, width: '233px', alignSelf: 'center'}}
            title={'Продать'}
            onClick={() => {
                viewModel.createOrder()
            }}
            isLoading={viewModel.state.order.state === AsyncState.LOADING}
            isEnabled={true}
        />
    }

    let shareButton = () => {
        return <ButtonAccent
            buttonStyle={{marginTop: 10, width: '233px', alignSelf: 'center'}}
            title={'Поделиться'}
            onClick={() => {
                if (viewModel.referralLink.state === AsyncState.SUCCESS) {
                    share(viewModel.referralLink.data)
                } else {
                    viewModel.loadReferralLink()
                }
            }}
            isLoading={viewModel.state.referralLink.state === AsyncState.LOADING}
            isEnabled={true}
        />
    }

    let navBar = () => {
        let data = viewModel.state.productFull.data
        if (data !== null) {
            return <OfferNavBar
                title={data.title}
                logo={data.image}
                onBackClick={() => {
                    navigate(-1)
                }}/>
        }
    }

    let compensation = () => {
        let price = viewModel.state.productFull.data?.compensations[0]?.price
        if (price) {
            return (
                <div className={styles.compensationContainer}>
                    <p className={styles.compensationDescription}>Твое вознаграждение:</p>
                    {viewModel.state.isCompensationVisible
                        && <div onClick={(e) => {
                            e.stopPropagation()
                            viewModel.toggleCompensations()
                        }}>
                            <p className={styles.compensation}>{`${getStringWithCents(price)} ${price.currency_symbol}`}</p>
                        </div>}
                    {!viewModel.state.isCompensationVisible
                        && <div
                            className={styles.compensationHideContainer}
                            onClick={(e) => {
                                e.stopPropagation()
                                viewModel.toggleCompensations()
                            }}>
                            <img src={icHide} className={styles.iconHide}/>
                        </div>}
                </div>
            )
        }
    }

    let header = () => {
        let data = viewModel.state.productFull.data
        let price = data?.price
        if (data !== null) {
            return (
                <div className={styles.headerContainer}>
                    <img src={data.image} className={styles.bigLogo}/>
                    <div className={styles.bigLogoMask}/>
                    <div
                        className={styles.backContainer}
                        onClick={() => {
                            navigate(-1)
                        }}>
                        <img
                            className={styles.iconBack}
                            src={ic_back}/>
                    </div>
                    <div className={styles.headerContent}>
                        <img src={data.image} className={styles.logo}/>
                        <p className={styles.title}>{data.title}</p>
                        {price &&
                            <p className={styles.price}>{`${getStringWithCents(price)} ${price.currency_symbol}`}</p>}
                        {sellButton()}
                        {shareButton()}
                    </div>
                </div>
            )
        }
    }

    return (
        <div className={styles.pageRoot}>
            {viewModel.state.productFull.state === AsyncState.LOADING && <Loader/>}
            <div className={styles.navbarContainer}>
                {isShowNavbar && navBar()}
            </div>
            <div className={styles.contentContainer}
                 onScroll={handleScroll}>
                {header()}
                {compensation()}
                <div style={{padding: '16px'}}>
                    {parse(viewModel.state.productFull.data?.description?.replace('\n', '<br>') ?? '')}
                </div>
            </div>
        </div>
    )
}

export default observer(ProductDetailsScreen)
