import styles from "./catalog_tabs.module.css";

interface Props {
    activeTab: CatalogTab,
    onTabClick: (tab: CatalogTab) => void
}

export enum CatalogTab {
    OFFERS,
    PRODUCTS
}

const CatalogTabs = ({activeTab, onTabClick}: Props) => {
    return <div className={styles.tabsContainer}>
        <div
            className={activeTab === CatalogTab.OFFERS ? styles.tabActive : styles.tabNotActive}
            onClick={() => {
                onTabClick(CatalogTab.OFFERS)
            }}>
            <p className={activeTab === CatalogTab.OFFERS ? styles.textActive : styles.textNotActive}>{`Сервисы`}</p>
        </div>
        <div
            className={activeTab === CatalogTab.PRODUCTS ? styles.tabActive : styles.tabNotActive}
            onClick={() => {
                onTabClick(CatalogTab.PRODUCTS)
            }}>
            <p className={activeTab === CatalogTab.PRODUCTS ? styles.textActive : styles.textNotActive}>{`Игры и ПО`}</p>
        </div>
    </div>
}

export default CatalogTabs;
