export interface ICost {
	currency: string
	currency_symbol: string
	value: number
}

const getCost = (cost: ICost): string => {
	if (typeof(cost?.value) !== `number`) {
		return `0\u00A0${cost?.currency_symbol}`;
	}
	return `${cost.value / 100}\u00A0${cost?.currency_symbol}`;
}

export default getCost;
