import dayjs, {Dayjs} from "dayjs"
import 'dayjs/locale/ru'
import utc from 'dayjs/plugin/utc'

export const timeUtil = {
    currentMonthRange: () => getMonthRange(today(), false),
    previousMonthRange: () => {
        let date = today()
            .subtract(1,'month')
        return getMonthRange(date, false)
    },
    availableMonths: () => {
        let date = today()
        let months: DateRange[] = []
        for (let i = 0; i < 12; i++) {
            months.push(
                getMonthRange(date, true)
            )
            date = date.subtract(1, 'month')
        }
        return months
    },
    clickDate: (dateStr: string) => {
        dayjs.extend(utc)
        return dayjs(dateStr).utc().format('DD.MM.YYYY HH:mm')
    },
    currentYearNumber: () => today().year(),
    currentMonthNumber: () => today().month(),
    currentTime: () => today().second(),
}

export interface DateRange {
    name: string,
    nameFull: string,
    startDate: string,
    endDate: string
}

const today = () => {
    dayjs.extend(utc)
    let date = dayjs().utc()
    date.locale('ru-ru')
    return date
}
const getMonthRange = (date: Dayjs, hasYear: boolean): DateRange => {
    let start = date.date(1)
    let end = date.endOf('month')
    if (hasYear) {
        return {
            name: getMonthWithYear(start),
            nameFull: getMonthWithFullYear(start),
            startDate: getDate(start),
            endDate: getDate(end)
        }
    } else {
        return {
            name: getMonth(start),
            nameFull: getMonth(start),
            startDate: getDate(start),
            endDate: getDate(end)
        }
    }
}

const getDate = (date: Dayjs): string => {
    return date.format('YYYY-MM-DD')
}
const getMonthWithYear = (date: Dayjs): string => {
    let dateStr = date.format('MMMM YY')
    return dateStr[0].toUpperCase() + dateStr.slice(1)
}

const getMonthWithFullYear = (date: Dayjs): string => {
    let dateStr = date.format('MMMM YYYY')
    return dateStr[0].toUpperCase() + dateStr.slice(1)
}

const getMonth = (date: Dayjs): string => {
    let dateStr = date.format('MMMM')
    return dateStr[0].toUpperCase() + dateStr.slice(1)
}
