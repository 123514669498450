import {CSSProperties, useState} from "react";
import styles from './input.module.css'

interface Props {
    placeholder: string
    onChange: (text: string) => void
    inputStyle?: CSSProperties
}
export const InputText = ({onChange, placeholder, inputStyle}: Props) => {

    const [company, setCompany] = useState('');

    return (
        <input
            className={styles.input}
            placeholder={placeholder}
            value={company}
            style={inputStyle}
            onChange={event => {
                const text = event.target.value
                onChange(text)
                setCompany(text)
            }}
        />
    )
}
