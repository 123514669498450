import {computed, makeObservable, observable, runInAction} from "mobx";
import {repository} from "../../../data/repo/Repository";
import {FlowType} from "../../../data/entity/FlowType";
import { Offer } from '../../../data/network/dto/OfferDto'
import { ReferralLink } from '../../../data/network/dto/ReferralLink'
import {Async, failure, initial, loading, success} from "../../../data/entity/Async";
import {CreatePaymentInfo, PromoCode} from "../../../data/network/dto/PaymentDto";

interface State {
    offer: Offer | null
    plainPromocode: Async<PromoCode>
    order: Async<CreatePaymentInfo>
    link: ReferralLink | null
    clickId: string | null
}

export class OfferDetailsViewModel {
    private offerId: string = ''
    offer: Offer | null = null
    request: boolean = false
    link: ReferralLink | null = null
    clickId: string | null = null
    query: string | null = null
    plainPromocode: Async<PromoCode> = initial()
    order: Async<CreatePaymentInfo> = initial()
    interval?: NodeJS.Timer
    captchaToken?: string;

    constructor(offerId: string) {
        this.offerId = offerId
        makeObservable(this,{
            offer: observable,
            request: observable,
            link: observable,
            clickId: observable,
            plainPromocode: observable,
            order: observable,
            state: computed,
            query: observable,
            captchaToken: observable
        })
        this.getOffer()
            .catch(console.log);
    }

    get state(): State {
        return {
            offer: this.offer,
            plainPromocode: this.plainPromocode,
            order: this.order,
            link: this.link,
            clickId: this.clickId
        }
    }

    public setCaptchaToken(token: string): void {
        runInAction(() => {
            this.captchaToken = token;
        });
    }

    getPromocode() {
        this.plainPromocode = loading()
        repository.requestPromoCode(this.offerId, this.clickId ?? '', this.captchaToken)
            .then((promocode) => {
                this.plainPromocode = success(promocode)
                this.clickId = null
            })
            .catch((e) => {
                this.plainPromocode = failure(e)
            })
    }

    createPayment() {
        this.order = loading()
        repository.createPayment(this.offerId, this.clickId)
            .then((order) => {
                this.order = success(order)
                this.clickId = null
            })
            .catch((e) => {
                this.order = failure(e)
            })
    }

    private async getOffer() {
        const offers = await repository.getOffer(this.offerId);
        runInAction(() => {
            this.offer = offers[0];
            if (this.offer.sharing_available === true) {
                this.getReferralLink(this.offerId)
                    .catch(console.log);
            }
        });
    }

    private async getReferralLink(offerId: string) {
        const link = await repository.getReferralLink(offerId)
        runInAction(() => {
            this.link = link as ReferralLink;
            this.getFollowInfo();
        });
    }

    getFollowInfo() {
        if (this.interval) {
            clearInterval(this.interval)
        }
        if (this.clickId) return
        if (this.offer?.flow === FlowType.SHARE) return
        if (this.offer?.flow === FlowType.SHARE_PRODUCT_EMAIL) return
        if (this.offer?.flow === FlowType.SELL_PRODUCT_EMAIL) return
        if (this.link?.id) {
            this.interval = setInterval(() => {
                repository.getFollowInfo(this.link?.id!)
                    .then((info) => {
                        if (info.execution_id) {
                            this.clickId = info.execution_id
                            clearInterval(this.interval)
                        }
                    })
                    .catch((e) => console.warn(e))
            }, 3000)
        }
    }

    deInit() {
        if (this.interval) {
            clearInterval(this.interval)
        }
    }
}
