import {CSSProperties, memo, useCallback} from "react";
import styles from "./order_navbar.module.css";
import ic_back from "../../images/icons/ic_20_close.svg";
import {useNavigate} from "react-router-dom";

interface Props {
    title: string
    style?: CSSProperties
    close?: () => void
}

export const SimpleNavBar = memo(({title, style, close}: Props) => {

    let navigate = useNavigate()

    const handleClose = useCallback(() => {
        !!close ? close() : navigate(-1)
    }, [close]);

    return <div
        className={styles.container}
        style={style}>
        <div
            className={styles.closeContainer}
            onClick={handleClose}>
            <img
                className={styles.iconClose}
                src={ic_back}/>
        </div>
        <p className={styles.title}>{title}</p>
    </div>
})
