import {makeAutoObservable} from "mobx";

interface State {
    platforms: string[]
    selected: string[]
    query: string
}

export class PlatformsViewModel {
    private allPlatforms: string[] = []
    private platforms: string[] = []
    private selected: string[] = []
    private query: string = ''


    get state(): State {
        return {
            platforms: this.platforms,
            selected: this.selected,
            query: this.query
        }
    }
    constructor() {
        makeAutoObservable(this)
    }

    initPlatforms(platforms: string[], selected: string[]) {
        if (this.platforms.length > 0) return
        this.platforms = platforms
        this.allPlatforms = platforms
        this.selected = selected
    }
    selectPlatform(platform: string) {
        if (this.selected.includes(platform)) {
            this.selected = this.selected.filter((item) => item !== platform)
        } else {
            this.selected = [...this.selected, platform]
        }
    }

    search(query: string) {
        if (query === this.query) return
        if (query === '') {
            this.platforms = this.allPlatforms
        } else {
            this.platforms = this.allPlatforms.filter((item) => item.toLowerCase().startsWith(query.toLowerCase()))
        }
        this.query = query
    }
}
