import {credentialManager} from "../../data/repo/CredentialManager";
import {makeAutoObservable} from "mobx";
import {eventBus} from "../../data/utils/EventBus";
import {LogoutEvent} from "../../data/utils/Events";
import { User } from '../../data/network/dto/UserDto'

interface State {
    user: User | null
}

export class ProfileViewModel {

    private user: User | null = null

    constructor() {
        makeAutoObservable(this)
        this.loadUser()
    }

    loadUser() {
        this.user = credentialManager.getUser()
    }

    logout() {
        eventBus.post(LogoutEvent)
    }

    get state(): State {
        return {
            user: this.user
        }
    }
}
