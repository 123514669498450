import LoginHeader from "../../components/login_header/LoginHeader";
import {useNavigate} from "react-router-dom";
import InputEmail from "../../components/input/InputEmail";
import styles from './login.module.css'
import InputPassword from "../../components/input/InputPassword";
import ButtonAccent from "../../components/button/ButtonAccent";
import {useEffect, useState} from "react";
import {LoginViewModel} from "./LoginViewModel";
import {observer} from "mobx-react";
import {reaction} from "mobx";
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";
import {showErrorSnack} from '../RootScreen'

const LoginScreen = () => {

    const navigate = useNavigate()
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const viewModel = new LoginViewModel()

    useEffect(
        () => reaction(() => viewModel.event,
            event => {
                if (event?.isSuccess === true) {
                    navigate('/', {replace: true})
                } else if (event?.errorMessage) {
                    showErrorSnack(event.errorMessage)
                }
            }),
        []
    )


    return (
        <div>
            <LoginHeader onBackClick={() => navigate(-1)} title={`Вход в аккаунт`}/>
            <div className={styles.container}>
                <InputEmail onChange={text => setEmail(text)}/>

                <InputPassword inputStyle={{marginTop: 12}} onChange={text => setPassword(text)}/>

                <ButtonAccent
                    buttonStyle={{marginTop: 32}}
                    onClick={() => viewModel.login(email, password)}
                    title={'Войти'}
                    isLoading={viewModel.state.isRequest}
                    isEnabled={!viewModel.state.isRequest}/>

                <div onClick={() => navigate('/reset')}>
                    <p className={styles.textButton}>Забыли пароль?</p>
                </div>
            </div>
        </div>
    )
}

export default observer(LoginScreen)
