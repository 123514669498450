import {repository} from "../../data/repo/Repository";
import {makeAutoObservable} from "mobx";

interface State {
    isRequest: boolean
}

export interface LoginViewEvent {
    isSuccess: boolean
    errorMessage?: any
}

export class LoginViewModel {
    private isRequest: boolean = false
    event: LoginViewEvent | null = null

    constructor() {
        makeAutoObservable(this)
    }

    get state(): State {
        return {
            isRequest: this.isRequest
        }
    }

    login(email: string, password: string) {
        this.isRequest = true
        repository.login(email, password)
            .then(() => {
                this.event = {
                    isSuccess: true
                }
            })
            .catch((e) => {
                this.event = {
                    isSuccess: false,
                    errorMessage: e
                }
                console.log(e)
            })
            .finally(() => {
                this.isRequest = false
            })
    }
}
