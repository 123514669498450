import { User } from '../network/dto/UserDto'

enum Keys {
    TOKEN,
    TOKEN_TIME,
    USER,
    PUSH,
    BUILD,
    CLICK_ID,
    PROCESSING_OFFER,
    COMPENSATION_VISIBILITY,
    REFERRAL_DIALOG,
}

export const preferences = {
    saveToken: (token: string | null) => {
        if (token !== null) localStorage.setItem(name(Keys.TOKEN), token)
        else localStorage.removeItem(name(Keys.TOKEN))
    },
    getToken:(): string | null => localStorage.getItem(name(Keys.TOKEN)),
    saveTokenTime: (time: number) => localStorage.setItem(name(Keys.TOKEN_TIME), String(time)),
    getTokenTime: (): number => Number(localStorage.getItem(name(Keys.TOKEN_TIME))),
    saveUser: (user: User | null) => {
        if (user !== null) localStorage.setItem(name(Keys.USER), JSON.stringify(user))
        else localStorage.removeItem(name(Keys.USER))
    },
    getUser: (): User | null => {
        const json = localStorage.getItem(name(Keys.USER))
        if (json == null) return null
        else {
            const user: User = JSON.parse(json)
            return user
        }
    },
    isCompensationVisible: (): boolean => (localStorage.getItem(name(Keys.COMPENSATION_VISIBILITY)) ?? 'true') === 'true',
    setCompensationVisibility:(isVisible: boolean) => {
        let value = isVisible ? 'true' : 'false'
        localStorage.setItem(name(Keys.COMPENSATION_VISIBILITY), value)
    },
}

function name(key: Keys): string {
    return Keys[key]
}
