import {ChangePasswordViewModel} from "./ChangePasswordViewModel";
import {useNavigate} from "react-router-dom";
import {useEffect, useMemo, useState} from "react";
import styles from './change_password.module.css'
import LoginHeader from "../../components/login_header/LoginHeader";
import ButtonAccent from "../../components/button/ButtonAccent";
import InputPassword from "../../components/input/InputPassword";
import {reaction} from "mobx";
import {AsyncState} from "../../data/entity/Async";
import {showErrorSnack} from "../RootScreen";
import {observer} from "mobx-react";

const ChangePasswordScreen = () => {
    const navigate = useNavigate()
    const viewModel = useMemo(() => new ChangePasswordViewModel(), [])
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [rePassword, setRePassword] = useState('');

    useEffect(() => reaction(() => viewModel.changeRequest,
        request => {
            if (request.state === AsyncState.SUCCESS) {
                navigate(-1)
            } else if (request.state === AsyncState.FAIL) {
                showErrorSnack(request.error)
            }
        }), []);

    return <div>
        <LoginHeader onBackClick={() => navigate(-1)} title={`Смена пароля`}/>
        <div className={styles.container}>
            <InputPassword
                placeholder='Старый пароль'
                onChange={text => setOldPassword(text)}/>

            <InputPassword
                inputStyle={{marginTop: 12}}
                placeholder='Новый пароль'
                onChange={text => setNewPassword(text)}/>

            <InputPassword
                inputStyle={{marginTop: 12}}
                placeholder='Повторите пароль'
                onChange={text => setRePassword(text)}/>

            <ButtonAccent
                buttonStyle={{marginTop: 32}}
                onClick={() => viewModel.changePassword(oldPassword, newPassword)}
                title={'Сохранить'}
                isLoading={viewModel.state.changeRequest.isLoading}
                isEnabled={Boolean(oldPassword.length && newPassword.length && newPassword === rePassword)}/>
        </div>
    </div>
}

export default observer(ChangePasswordScreen)
