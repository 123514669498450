import {makeAutoObservable} from "mobx";
import {eventBus, subscribe} from "./data/utils/EventBus";
import {LoginEvent, LogoutEvent} from "./data/utils/Events";
import {credentialManager} from "./data/repo/CredentialManager";

export enum AppEvent {
    None,
    Login,
    Logout
}

export class AppViewModel {
    event: AppEvent = AppEvent.None

    constructor() {
        makeAutoObservable(this)
        eventBus.register(this)
    }
    @subscribe(LogoutEvent)
    private onLogout() {
        credentialManager.logOut()
        this.event = AppEvent.Logout
    }

    @subscribe(LoginEvent)
    private onLogin() {
        this.event = AppEvent.Login
    }
}
