import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useMemo} from "react";
import {PlatformsViewModel} from "./PlatformsViewModel";
import styles from './platforms.module.css'
import ic_back from "../../../images/icons/ic_20_back_filter.svg";
import {SearchInput} from "../../../components/input/SearchInput";
import {PlatformItem} from "../../../components/platform/PlatformItem";
import {observer} from "mobx-react";
import {FilterViewModel} from "../FilterViewModel";
import ButtonAccent from "../../../components/button/ButtonAccent";

export type PlatformType = 'platforms' | 'years' | 'activation' | 'publishers'

export const platformsScreenState = (type: PlatformType, title: string) => {
    return {
        type: type,
        title: title
    }
}

interface Props {
    filterViewModel: FilterViewModel
}

const PlatformsScreen = ({filterViewModel}: Props) => {

    let navigate = useNavigate()
    let location = useLocation()
    let viewModel = useMemo(() => new PlatformsViewModel(), [])

    useEffect(() => {
        let type: PlatformType = location.state.type
        if (type === "years") {
            viewModel.initPlatforms(
                filterViewModel.state.platformsInfo.data?.years ?? [],
                filterViewModel.state.filterInfo.releaseYears ?? []
            )
        } else if (type === "platforms") {
            viewModel.initPlatforms(
                filterViewModel.state.platformsInfo.data?.platforms ?? [],
                filterViewModel.state.filterInfo.platforms ?? []
            )
        } else if (type === "activation") {
            viewModel.initPlatforms(
                filterViewModel.state.platformsInfo.data?.platformsActivation ?? [],
                filterViewModel.state.filterInfo.activationPlatforms ?? []
            )
        } else {
            viewModel.initPlatforms(
                filterViewModel.state.platformsInfo.data?.publishers ?? [],
                filterViewModel.state.filterInfo.publishers ?? []
            )
        }
    }, [])

    const navBar = () => {
        return <div className={styles.navbarContainer}>
            <div
                className={styles.closeContainer}
                onClick={() => {
                    navigate(-1)
                }}>
                <img
                    className={styles.iconClose}
                    src={ic_back}/>
            </div>
            <p className={styles.barTitle}>{location.state.title}</p>
        </div>
    }

    return <div className={styles.pageRoot}>
        {navBar()}
        <div className={styles.searchContainer}>
            <SearchInput
                initQuery={viewModel.state.query}
                onChange={(q) => viewModel.search(q)}
                containerStyle={{padding: '12px 16px', width: '100%'}}/>
        </div>
        <div className={styles.contentContainer}>
            <ul>
                {viewModel.state.platforms.map((platform) => {
                    return <li>
                        <PlatformItem
                            key={platform}
                            title={platform}
                            selected={viewModel.state.selected.includes(platform)}
                            onClick={() => viewModel.selectPlatform(platform)}/>
                    </li>
                })}
            </ul>
        </div>
        <div className={styles.buttonsContainer}>
            <ButtonAccent
                title='Сохранить'
                onClick={() => {
                    let type: PlatformType = location.state.type
                    if (type === "years") {
                        filterViewModel.setYears(viewModel.state.selected)
                    } else if (type === "platforms") {
                        filterViewModel.setPlatforms(viewModel.state.selected)
                    } else if (type === "activation") {
                        filterViewModel.setPlatformsActivation(viewModel.state.selected)
                    } else {
                        filterViewModel.setPublishers(viewModel.state.selected)
                    }
                    navigate(-1)
                }}
                isLoading={false}
                isEnabled={true}/>
        </div>
    </div>
}

export default observer(PlatformsScreen)
