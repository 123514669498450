import {credentialManager} from "../repo/CredentialManager";
import {Navigate} from "react-router-dom";
import * as React from "react";

export const CheckAuth = ({children}) => {

    if (credentialManager.isLogged()) {
        return children;
    } else {
        return <Navigate to="/intro" replace/>;
    }
}
