import styles from './item_platform.module.css'
import icCheckbox from '../../images/icons/ic_20_checkbox.svg'
import icCheckboxActive from '../../images/icons/ic_20_checkbox_active.svg'
interface Props {
    title: string
    selected: boolean
    onClick: () => void
}

export const PlatformItem = ({title, selected, onClick}:Props) => {
    return <div className={styles.container} onClick={onClick}>
        <img src={selected ? icCheckboxActive : icCheckbox} className={styles.checkBox}/>
        <p className={styles.title}>{title}</p>
    </div>
}
