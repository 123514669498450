import styles from "./bottom_bar.module.css"
import ic_catalog from "../../images/icons/ic_24_catalog.svg";
import ic_catalog_active from "../../images/icons/ic_24_catalog_active.svg";
import ic_statistic from "../../images/icons/ic_24_statistic.svg";
import ic_statistic_active from "../../images/icons/ic_24_statistic_active.svg";
import ic_profile from "../../images/icons/ic_24_profile.svg";
import ic_profile_active from "../../images/icons/ic_24_profile_active.svg";

interface Props {
    activeItem: BottomItem,
    onItemClick: (item: BottomItem) => void,
}

export enum BottomItem {
    CATALOG,
    STATISTIC,
    PROFILE
}

const BottomBar = ({activeItem, onItemClick}: Props) => {
    return (
        <div className={styles.container}>
            <div className={styles.segment} onClick={() => { onItemClick(BottomItem.CATALOG) }}>
                <div className={activeItem === BottomItem.CATALOG ? styles.iconContainerActive : styles.iconContainer}>
                    <img
                        className={styles.icon}
                        src={activeItem === BottomItem.CATALOG ? ic_catalog_active : ic_catalog}
                    />
                </div>
                <p className={styles.text}>Каталог</p>
            </div>
            <div className={styles.segment} onClick={() => { onItemClick(BottomItem.STATISTIC) }}>
                <div className={activeItem === BottomItem.STATISTIC ? styles.iconContainerActive : styles.iconContainer}>
                    <img
                        className={styles.icon}
                        src={activeItem === BottomItem.STATISTIC ? ic_statistic_active : ic_statistic}
                    />
                </div>
                <p className={styles.text}>Статистика</p>
            </div>
            <div className={styles.segment} onClick={() => { onItemClick(BottomItem.PROFILE) }}>
                <div className={activeItem === BottomItem.PROFILE ? styles.iconContainerActive : styles.iconContainer}>
                    <img
                        className={styles.icon}
                        src={activeItem === BottomItem.PROFILE ? ic_profile_active : ic_profile}
                    />
                </div>
                <p className={styles.text}>Профиль</p>
            </div>
        </div>
    );
}

export default BottomBar;
