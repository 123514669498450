import { CSSProperties } from 'react'
import styles from './search_input.module.css'

interface Props {
	initQuery: string
	onChange: (text: string) => void
	containerStyle?: CSSProperties
}

export const SearchInput = ({ onChange, containerStyle, initQuery }: Props) => {

	return (
		<div style={containerStyle}>
			<div className={styles.searchContainer}>
				<input
					className={styles.input}
					placeholder='Поиск'
					value={initQuery}
					onChange={event => {
						const text = event.target.value
						onChange(text)
					}}
				/>
			</div>
		</div>
	)
}
