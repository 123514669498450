import {DateRange, timeUtil} from "../../data/utils/TimeUtil";
import {Revenue} from "../../data/entity/Revenue";
import {Async, failure, loading, success} from "../../data/entity/Async";
import {StatisticOffer} from "../../data/network/dto/StatisticOffer";
import {computed, makeObservable, observable} from "mobx";
import {credentialManager} from "../../data/repo/CredentialManager";
import {repository} from "../../data/repo/Repository";
import {groupBy} from "../../data/utils/Extensions";

interface State {
    range: StatisticRange
    revenues: Revenue[]
    offers: Async<StatisticOffer[]>
}

export type StatisticMonthType = 'current' | 'previous' | 'custom'
export interface StatisticRange {
    type: StatisticMonthType
    range: DateRange
}
export class StatisticViewModel {
    range: StatisticRange = {
        type: "current",
        range: timeUtil.currentMonthRange()
    }
    revenues: Revenue[] = []
    offers: Async<StatisticOffer[]> = loading()
    private abortController: AbortController = new AbortController()

    constructor() {
        makeObservable(this,{
            range: observable,
            revenues: observable,
            offers: observable,
            state: computed
        })
        if (credentialManager.isLogged()) {
            this.onMount()
        }
    }

    onMount() {
        this.loadStats(this.range)
    }

    get state() : State {
        return {
            range: this.range,
            revenues: this.revenues,
            offers: this.offers
        }
    }

    loadStatistic(range: StatisticRange) {
        this.abortController.abort()
        this.loadStats(range)
    }

    private loadStats(range: StatisticRange) {
        this.abortController = new AbortController()
        this.offers = loading()
        this.revenues = []
        this.range = range
        repository.getStatistic(this.range.range.startDate, this.range.range.endDate, this.abortController.signal)
            .then((offers) => {
                const currencies = groupBy(offers, offer => offer.target_actions[0].target_action_currency_symbol)
                this.revenues = Object.entries(currencies).map((entry): Revenue => {
                    return {
                        sum: entry[1].reduce((total, offer) => total += offer.revenue, 0),
                        currency: entry[0]
                    }
                })
                this.offers = success(offers)
            })
            .catch((e) =>{
                console.log(e)
                this.offers = failure(e)
            })
    }
}
