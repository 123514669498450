import {useMemo} from "react";
import {FilterViewModel} from "./FilterViewModel";
import {ProductsViewModel} from "../products/list/ProductsViewModel";
import {Route, Routes} from "react-router-dom";
import {CheckAuth} from "../../data/utils/CheckAuth";
import FiltersScreen from "./FiltersScreen";
import PlatformsScreen from "./platforms/PlatformsScreen";

interface Params {
    productsViewModel: ProductsViewModel
}
export const FiltersFlowScreen = ({productsViewModel}: Params) => {

    const viewModel = useMemo(() => new FilterViewModel(productsViewModel.filterInfo), [])

    return <div>
        <Routes>
            <Route index element={
                <CheckAuth>
                    <FiltersScreen viewModel={viewModel}/>
                </CheckAuth>
            }/>
            <Route path='platforms' element={
                <CheckAuth>
                    <PlatformsScreen filterViewModel={viewModel}/>
                </CheckAuth>
            }/>
        </Routes>
    </div>
}

