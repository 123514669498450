import {credentialManager} from "../data/repo/CredentialManager";
import {Navigate} from "react-router-dom";
import React from "react";
import { Bounce, toast } from 'react-toastify'

export const RootScreen = ()=> {
    return credentialManager.isLogged() ?  <Navigate to="/catalog"/> :  <Navigate to="/intro"/>
}

export const showErrorSnack = (e?: any) => {
    toast.error(e.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    })
}

export const share = (url?: string | null) => {
    if (url) {
        navigator.share({
            url: url
        })
            .then(() => {
                console.log("Successfully shared");
            })
            .catch((error) => {
                console.error("Something went wrong", error);
            })
    }
}
