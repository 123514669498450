import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
import {
	CollectionView,
	Orientation,
} from '../../../components/collection/VericalCollection'
import { MoreErrorItem } from '../../../components/more/MoreErrorItem'
import { MoreLoadingItem } from '../../../components/more/MoreLoadingItem'
import { OfferItem } from '../../../components/offer/OfferItem'
import {OfferGroup} from "../../../components/offer_group/OfferGroup"
import OffersHeaderBlock from '../../../components/offers/OffersHeaderBlock'
import { AsyncState } from '../../../data/entity/Async'
import { OffersViewModel } from './OffersViewModel'
import styles from './offers.module.css'
import {Offer} from "../../../data/network/dto/OfferDto";

interface Props {
	viewModel: OffersViewModel
}

const OffersScreen = ({ viewModel }: Props) => {
	let navigate = useNavigate()

	const moreItem = (state: AsyncState): JSX.Element => {
		if (state === AsyncState.LOADING) {
			return <MoreLoadingItem key={'moreItem'} />
		} else if (state === AsyncState.FAIL) {
			return (
				<MoreErrorItem
					onRefresh={() => {
						viewModel.loadMoreOffers()
					}}
					key={'MoreErrorItem'}
				/>
			)
		} else {
			return <div key={'noItem'} />
		}
	}

	return (
		<div className={styles.pageRoot}>
			<div className={styles.headerContentContainer}>
				<OffersHeaderBlock viewModel={viewModel} />
			</div>
			<CollectionView
				id={`offers_list`}
				orientation={Orientation.VERTICAL}
				items={viewModel.state.offers.data?.offers ?? []}
				mapItem={(offer: Offer) => {
                    if (offer.flow === `similar_offer_group`) {
                        return (
                            <OfferGroup
                                key={offer.id}
                                offer={offer}
                            />
                        );
                    }

                    return (
                        <OfferItem
                            key={offer.id}
                            offer={offer}
                            onClick={offer => navigate(`offers/${offer.id}`)}
                        />
                    )
                }}
				loadMore={() => {
					viewModel.loadMoreOffers()
				}}
				moreItem={() => moreItem(viewModel.state.loadMore)}
				containerStyle={{ paddingTop: '0px', paddingBottom: '0px', height: '100%'}}
			/>
		</div>
	)
}

export default observer(OffersScreen)
