import {Async, failure, initial, loading, success} from "../../../data/entity/Async";
import {ProductOrder} from "../../../data/network/dto/PaymentDto";
import {computed, makeObservable, observable} from "mobx";
import {repository} from "../../../data/repo/Repository";

interface State {
    order: Async<ProductOrder>
    type: OrderType
    request: boolean
    sendKeyRequest: Async<void>
}

export enum OrderType {
    PRODUCT,
    SELL_PROMOCODE,
    SHARE_PROMOCODE
}

export class ProductOrderViewModel {
    private orderId: string = ''
    type: OrderType = OrderType.PRODUCT
    order: Async<ProductOrder> = loading()
    sendKeyRequest: Async<void> = initial()
    request: boolean = false
    interval?: NodeJS.Timer
    private abortController: AbortController = new AbortController()

    constructor(orderId: string, type: OrderType) {
        this.orderId = orderId
        this.type = type
        makeObservable(this, {
            type: observable,
            order: observable,
            request: observable,
            sendKeyRequest: observable,
            state: computed
        })
        this.getOrderPeriodic()
    }

    get state(): State {
        return {
            order: this.order,
            type: this.type,
            request: this.request,
            sendKeyRequest: this.sendKeyRequest
        }
    }

    checkState() {
        this.request = true
        this.getOrder()
    }

    sendEmailAgain() {
        this.sendKeyRequest = loading()
        repository.sendKeyAgain(this.orderId)
            .then(() => {
                this.sendKeyRequest = success(undefined)
            })
            .catch((e) => {
                this.sendKeyRequest = failure(e)
            })
    }

    private getOrderPeriodic() {
        if (this.interval) {
            clearInterval(this.interval)
        }
        this.interval = setInterval(() => {
            this.getOrder()
        }, 3250)
    }

    private getOrder() {
        this.abortController.abort()
        this.abortController = new AbortController()
        repository.getProductOrderStatus(this.orderId, this.abortController.signal)
            .then((order) => {
                this.order = success(order)
                if (order.status !== "in_process" && order.status !== "new") {
                    clearInterval(this.interval)
                }
            })
            .catch((e) => {
                console.log(e)
                this.order = failure(e)
            })
            .finally(() => {
                this.request = false
            })
    }

    deInit() {
        if (this.interval) {
            clearInterval(this.interval)
        }
    }
}
