import styles from "./button_secondary.module.css";
import {CSSProperties} from "react";

interface Props {
    title: string,
    onClick: () => void,
    buttonStyle?: CSSProperties
}

const ButtonSecondary = ({onClick, title, buttonStyle }: Props) => {
    return (
        <div
            className={styles.container}
            style={buttonStyle}
            onClick={() => {
                onClick()
            }}>
            <p className={styles.text}>{title}</p>
        </div>
    );
}

export default ButtonSecondary;
