import {Async, failure, loading, success} from "../../../../data/entity/Async";
import {CreatePaymentInfo, ProductOrder} from "../../../../data/network/dto/PaymentDto";
import {computed, makeObservable, observable} from "mobx";
import {repository} from "../../../../data/repo/Repository";

interface State {
    order: Async<CreatePaymentInfo>
    request: boolean
}
export class PromocodeViewModel {
    private orderId: string = ''
    order: Async<CreatePaymentInfo> = loading()
    request: boolean = false
    interval?: NodeJS.Timer
    private abortController: AbortController = new AbortController()

    constructor(orderId: string) {
        this.orderId = orderId
        makeObservable(this, {
            order: observable,
            request: observable,
            state: computed
        })
        this.getOrderPeriodic()
    }

    get state(): State {
        return {
            order: this.order,
            request: this.request
        }
    }

    checkState() {
        this.request = true
        this.getOrder()
    }

    private getOrderPeriodic() {
        if (this.interval) {
            clearInterval(this.interval)
        }
        this.interval = setInterval(() => {
            this.getOrder()
        }, 3250)
    }

    private getOrder() {
        this.abortController.abort()
        this.abortController = new AbortController()
        repository.getPaymentStatus(this.orderId, this.abortController.signal)
            .then((order) => {
                this.order = success(order)
                if (order.status !== "in_process" && order.status !== "new") {
                    clearInterval(this.interval)
                }
            })
            .catch((e) => {
                console.log(e)
                this.order = failure(e)
            })
            .finally(() => {
                this.request = false
            })
    }

    deInit() {
        if (this.interval) {
            clearInterval(this.interval)
        }
    }
}
