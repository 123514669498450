import {BottomItem} from "../../components/bottom_bar/BottomBar";
import {makeAutoObservable} from "mobx";

interface State {
    selectedItem: BottomItem
}

export class MainViewModel {
    private selectedItem: BottomItem = BottomItem.CATALOG

    constructor() {
        makeAutoObservable(this)
    }

    get state(): State {
        return {
            selectedItem: this.selectedItem
        }
    }

    selectItem(item: BottomItem) {
        this.selectedItem = item
    }
}
