import {StatisticOffer} from "../../data/network/dto/StatisticOffer";
import styles from './statistic_offer.module.css'
import icExpand from '../../images/icons/ic_24_expand.svg'
import icCollapse from '../../images/icons/ic_24_collapse.svg'
import {StatisticActionItem} from "./action/StatisticActionItem";
import {useState} from "react";
import getCost from "../../helpers/getCost";

interface Props {
    offer: StatisticOffer
    onClick: (id: string) => void
}

export const StatisticOfferItem = ({offer, onClick}: Props) => {

    const [expanded, expand] = useState(false);

    return <li className={styles.root}>
        <div className={styles.container}>
            <div className={styles.titleContainer}>
                <p className={styles.title}>{offer.offer_title}</p>
                <div onClick={() => {
                    expand(v => !v)
                }}>
                    <img
                        src={expanded ? icCollapse : icExpand}
                        className={styles.iconCollapse}
                        alt={``}
                    />
                </div>
            </div>
            <div className={styles.columnsContainer}>
                <div className={styles.column}>
                    <p className={styles.columnTitle}>{getCost(offer.revenue_cost)}</p>
                    <p className={styles.columnSubtitle}>{`Доход`}</p>
                </div>
                <div className={styles.column}>
                    <p className={styles.columnTitle}>{`${offer.installation_count + offer.follow_link_count}`}</p>
                    <p className={styles.columnSubtitle}>{`Переходов`}</p>
                </div>
                <div className={styles.column}>
                    <p className={styles.columnTitle}>{`${offer.target_actions.reduce((sum, action) => sum += action.execution_count, 0)}`}</p>
                    <p className={styles.columnSubtitle}>{`Действий`}</p>
                </div>
            </div>
            {expanded && offer.target_actions.map((action, index) => (
                <StatisticActionItem key={index} action={action} />
            ))}
            {expanded && (
                <div className={styles.detailsRow}>
                    <div onClick={() => onClick(offer.offer_id)}>
                        <p className={styles.details}>Детализация</p>
                    </div>
                </div>
            )}
        </div>
    </li>
}
