import {StatisticViewModel} from "../StatisticViewModel";
import styles from './ranges.module.css'
import ic_back from "../../../images/icons/ic_20_close.svg";
import {useNavigate} from "react-router-dom";
import {DateRange, timeUtil} from "../../../data/utils/TimeUtil";
import {useMemo} from "react";

interface Props {
    viewModel: StatisticViewModel
}
export const RangesScreen = ({viewModel}:Props) => {

    let navigate = useNavigate()
    let months = useMemo(() => {
        let months = timeUtil.availableMonths()
        let chunkedMonths: DateRange[][] = []
        while (months.length > 0)
        chunkedMonths.push(months.splice(0, 2))
        return chunkedMonths
    },[])
    const navBar = () => {
        let hasRange = viewModel.state.range.type === 'custom'
        return <div className={styles.navbarContainer}>
            <div
                className={styles.closeContainer}
                onClick={() => {
                    navigate(-1)
                }}>
                <img
                    className={styles.iconClose}
                    src={ic_back}/>
            </div>
            <p className={styles.barTitle}>Выбор даты</p>
            {hasRange && <div onClick={() => {
                viewModel.loadStatistic({
                    type: "current",
                    range: timeUtil.currentMonthRange()
                })
                navigate(-1)
            }}>
                <p className={styles.barClear}>Сбросить</p>
            </div>}
        </div>
    }

    const twoMonths = (chunk: DateRange[]) => {
        const isCustom = viewModel.state.range.type === 'custom'
        const isFirstSelected = isCustom && chunk[0].name === viewModel.state.range.range.name
        const isSecondSelected = isCustom && chunk[1].name === viewModel.state.range.range.name
        return <div className={styles.monthsContainer}>
            <div
                className={isFirstSelected ? styles.activeMonthContainer : styles.inactiveMonthContainer}
            onClick={() => {
                viewModel.loadStatistic({
                    type: 'custom',
                    range: chunk[0]
                })
                navigate(-1)
            }}>
                <p className={isFirstSelected ? styles.activeMonth : styles.inactiveMonth}>{chunk[0].nameFull}</p>
            </div>
            <div
                className={isSecondSelected ? styles.activeMonthContainer : styles.inactiveMonthContainer}
            onClick={() => {
                viewModel.loadStatistic({
                    type: 'custom',
                    range: chunk[1]
                })
                navigate(-1)
            }}>
                <p className={isSecondSelected ? styles.activeMonth : styles.inactiveMonth}>{chunk[1].nameFull}</p>
            </div>
        </div>
    }

    return <div className={styles.pageRoot}>
        {navBar()}
        <div className={styles.contentContainer}>
            <p className={styles.title}>Выберите любой месяц</p>
            {months.map((chunk) => twoMonths(chunk))}
        </div>
    </div>
}
