import styles from './statistinc_details.module.css'
import ic_back from "../../../images/icons/ic_20_back.svg";
import icGrowth from '../../../images/icons/ic_24_growth.svg'
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {observer} from "mobx-react";
import {useMemo} from "react";
import {StatisticDetailsViewModel} from "./StatisticDetailsViewModel";
import {timeUtil} from "../../../data/utils/TimeUtil";
import {ClickInfo} from "../../../data/network/dto/ClickInfo";
import {AsyncState} from "../../../data/entity/Async";
import {Loader} from "../../../components/loader/Loader";
import getCost from "../../../helpers/getCost";

const StatisticDetailsScreen = () => {

    const navigate = useNavigate()
    const location = useLocation()
    const {offerId} = useParams()

    if (!offerId) {
        navigate(-1)
    }

    const viewModel = useMemo(() => {
        return new StatisticDetailsViewModel(offerId!!, location.state.start, location.state.end)
    }, [location.state.end, location.state.start, offerId])

    const navBar = () => {
        return (
            <div className={styles.navbarContainer}>
                <div
                    className={styles.closeContainer}
                    onClick={() => {
                        navigate(-1)
                    }}>
                    <img
                        className={styles.iconClose}
                        src={ic_back}
                        alt={``}
                    />
                </div>
                <p className={styles.barTitle}>{location.state.title ?? 'Детализация'}</p>
            </div>
        );
    }

    const clickItem = (click: ClickInfo) => {
        return (
            <li key={click.click_id}>
                <div className={styles.itemRootContainer}>
                    <div className={styles.itemContainer}>
                        <p className={styles.date}>{timeUtil.clickDate(click.click_date)}</p>
                        {click.status_value === 'complete' && <p className={styles.actionSuccess}>Выполнено</p>}
                        {click.status_value === 'pending' && <p className={styles.actionWait}>Ожидание</p>}
                        {click.status_value !== 'pending' && click.status_value !== 'complete' && <p className={styles.actionError}>Отмена</p>}
                        <p className={styles.amount}>{`+${getCost(click.revenue)}`}</p>
                    </div>
                </div>
            </li>
        );
    }

    return <div className={styles.pageRoot}>
        {navBar()}
        <div className={styles.contentContainer}>
            <div className={styles.headerContainer}>
                <img
                    src={icGrowth}
                    className={styles.icon}
                    alt={``}
                />
                <p className={styles.title}>{'Детальная статистика за выбранный период. \nКаждая строчка — переход по твоей персональной ссылке'}</p>
            </div>
            <div className={styles.tabs}>
                <p className={styles.tab1}>{`Время`}</p>
                <p className={styles.tab2}>{`Целевое действие`}</p>
                <p className={styles.tab3}>{`Твой доход`}</p>
            </div>
            {viewModel.state.clicks.state === AsyncState.LOADING && <Loader/>}
            {viewModel.state.clicks.state === AsyncState.SUCCESS && (
                <ul>
                    {viewModel.state.clicks.data?.map(clickItem)}
                </ul>
            )}
        </div>
    </div>
}

export default observer(StatisticDetailsScreen)
