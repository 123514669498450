import {Route, Routes, useParams} from "react-router-dom";
import React, {useMemo} from "react";
import {ProductDetailsViewModel} from "./ProductDetailsViewModel";
import {CheckAuth} from "../../../data/utils/CheckAuth";
import ProductOrderScreen from "../order/ProductOrderScreen";
import ProductDetailsScreen from "./ProductDetailsScreen";
import {OrderType} from "../order/ProductOrderViewModel";

export const ProductFlowScreen = () => {

    let {productId} = useParams()
    let viewModel = useMemo(() => new ProductDetailsViewModel(productId!), [])

    return <div>
        <Routes>
            <Route index element={
                <CheckAuth>
                    <ProductDetailsScreen viewModel={viewModel}/>
                </CheckAuth>
            }/>
            <Route path="order/:orderId/*" element={
                <CheckAuth>
                    <ProductOrderScreen type={OrderType.PRODUCT}/>
                </CheckAuth>
            }/>
        </Routes>
    </div>
}
