import {Async, failure, initial, loading, success} from "../../data/entity/Async";
import {makeAutoObservable} from "mobx";
import {repository} from "../../data/repo/Repository";

interface State {
    changeRequest: Async<boolean>
}
export class ChangePasswordViewModel {
    changeRequest: Async<boolean> = initial()

    constructor() {
        makeAutoObservable(this)
    }

    get state(): State {
        return {
            changeRequest: this.changeRequest
        }
    }

    changePassword(oldPassword: string, newPassword: string) {
        this.changeRequest = loading()
        repository.changePassword(oldPassword, newPassword)
            .then(() => {
                this.changeRequest = success(true)
            })
            .catch((e) => {
                this.changeRequest = failure(e)
            })
    }
}
