import {FilterViewModel} from "./FilterViewModel";
import styles from './filters.module.css'
import {useNavigate} from "react-router-dom";
import icArrow from '../../images/icons/ic_16_arrow_filter.svg'
import icCheckbox from '../../images/icons/ic_20_checkbox.svg'
import icCheckboxActive from '../../images/icons/ic_20_checkbox_active.svg'
import {observer} from "mobx-react";
import ic_back from "../../images/icons/ic_20_close.svg";
import {hasActiveFilters} from "../../data/entity/FilterInfo";
import {share} from "../RootScreen";
import ButtonAccent from "../../components/button/ButtonAccent";
import {platformsScreenState, PlatformType} from "./platforms/PlatformsScreen";

interface Props {
    viewModel: FilterViewModel
}
const FiltersScreen = ({viewModel}:Props) => {

    let navigate = useNavigate()

    const content = () => {
        let yearsCount = viewModel.state.filterInfo.releaseYears?.length ?? 0
        let publishersCount = viewModel.state.filterInfo.publishers?.length ?? 0
        let platformsCount = viewModel.state.filterInfo.platforms?.length ?? 0
        let platformsActivationCount = viewModel.state.filterInfo.activationPlatforms?.length ?? 0
        return <div className={styles.contentContainer}>
            {item('Год выпуска', yearsCount, () => {
                let type: PlatformType = "years"
                navigate('platforms', {
                    state: platformsScreenState(
                        type,
                        'Год выпуска'
                    )
                })
            })}
            {item('Издатель', publishersCount, () => {
                let type: PlatformType = "publishers"
                navigate('platforms', {
                    state: platformsScreenState(
                        type,
                        'Издатель'
                    )
                })
            })}
            {item('Платформа активации', platformsActivationCount, () => {
                let type: PlatformType = "activation"
                navigate('platforms', {
                    state: platformsScreenState(
                        type,
                        'Платформа активации'
                    )
                })
            })}
            {item('ОС', platformsCount, () => {
                let type: PlatformType = "platforms"
                navigate('platforms', {
                    state: platformsScreenState(
                        type,
                        'ОС'
                    )
                })
            })}
            {promoItem()}
        </div>
    }

    const item = (title: string, count: number, onClick: () => void) => {
        return <div className={styles.itemContainer} onClick={onClick}>
            <div className={styles.itemTextContainer}>
                <p className={styles.itemTitle}>{title}</p>
                {count > 0 && <p className={styles.itemSubtitle}>{`Выбрано: ${count}`}</p>}
            </div>
            <img src={icArrow} className={styles.itemIcon}/>
        </div>
    }

    const promoItem = () => {
        let isPromo = viewModel.state.filterInfo.isPromo ?? false
        return <div
            className={styles.itemContainer}
            onClick={() =>{
                viewModel.setPromo(!isPromo)
            }}>
            <div className={styles.itemTextContainer}>
                <p className={styles.itemTitle}>По акции</p>
            </div>
            <img src={isPromo ? icCheckboxActive : icCheckbox} className={styles.itemCheckBox}/>
        </div>
    }

    const navBar = () => {
        let hasFilters = hasActiveFilters(viewModel.state.filterInfo)
        return <div className={styles.navbarContainer}>
            <div
                className={styles.closeContainer}
                onClick={() => {
                    navigate(-1)
                }}>
                <img
                    className={styles.iconClose}
                    src={ic_back}/>
            </div>
            <p className={styles.barTitle}>Фильтры</p>
            {hasFilters && <div onClick={() => viewModel.reset()}>
                <p className={styles.barClear}>Сбросить</p>
            </div>}
        </div>
    }

    return <div className={styles.pageRoot}>
        {navBar()}
        {content()}
        <div className={styles.buttonsContainer}>
            <ButtonAccent
                title='Показать'
                onClick={() => {
                    viewModel.applyFilter()
                    navigate(-1)
                }}
                isLoading={false}
                isEnabled={true}/>
        </div>
    </div>
}

export default observer(FiltersScreen)
