import {Plan, TargetAction} from "../../../data/network/dto/StatisticOffer";
import style from './statistic_action.module.css'
import React from "react";
import {NotSuccessPlanView, SuccessPlanView} from "../plan/SuccessPlanView";
import getCost from "../../../helpers/getCost";

interface Props {
    action: TargetAction
}

export const StatisticActionItem = ({action}: Props) => {

    const dataRow = (title: string, info: string, hasDivider: boolean) => {
        return (
            <div className={hasDivider ? style.actionContainerBordered : style.actionContainer}>
                <p className={style.actionKey}>{title}</p>
                <p className={style.actionValue}>{info}</p>
            </div>
        );
    }

    const planRow = (plan: Plan) => {
        return (
            <div key={plan.id}>
                {dataRow('При выполнении плана:', getCost(plan.cost), true)}
                {dataRow('План:', `${plan.amount}`, false)}
                {action.execution_count >= plan.amount
                    && <SuccessPlanView plan={plan.amount} count={action.execution_count}/>}
                {action.execution_count < plan.amount
                    && <NotSuccessPlanView plan={plan.amount} count={action.execution_count}/>}
            </div>
        );
    }

    const plans = action.plans ?? []

    return (
        <div className={style.container}>
            <p className={style.title}>{action.target_action_title}</p>
            <div className={style.actionsContainer}>
                {dataRow('Целевых действий:', `${action.execution_count} шт.`, true)}
                {dataRow('Базовое вознаграждение:', getCost(action.target_action_cost), plans.length > 0)}
                {plans.map(planRow)}
            </div>
        </div>
    );
}
