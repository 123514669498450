//import logo from "../../images/intro/logo_intro_image.svg";
import logoIntroText from "../../images/intro/logo_intro_text.svg";
import styles from "./intro.module.css";
import ButtonAccent from "../../components/button/ButtonAccent";
import {useNavigate} from "react-router-dom";
import ButtonSecondary from "../../components/button/ButtonSecondary";
import {credentialManager} from "../../data/repo/CredentialManager";

function IntroScreen() {

    const navigate = useNavigate()

    if (credentialManager.getUser() != null) {
    }

    return (
        <div className={styles.pageRoot}>
            <div className={styles.intro}>
                <div className={styles.logo}>
                    <svg width="88" height="85" viewBox="0 0 88 85" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.logoImage}>
                        <rect x="81" width="7" height="85" fill="url(#paint0_linear_1271_407)"/>
                        <rect width="81" height="85" fill="white"/>
                        <path id={`bg`} d="M47.703 20.1514C44.1103 20.8046 35.5913 22.4104 28.7871 23.6896C21.9828 24.9416 15.8045 26.1936 15.0424 26.4386C11.1503 27.6633 7.01332 30.875 5.27142 34.0049C4.07386 36.1551 3.23013 39.0401 3.03961 41.5169C2.52248 48.6478 7.12219 55.8875 13.7087 58.2554C15.4779 58.9087 24.4323 60.7322 36.8161 62.9912C41.0076 63.7533 46.3421 64.7603 48.6556 65.223C52.2482 65.9579 53.5002 66.0668 57.0929 66.094C61.0394 66.1212 61.4749 66.0668 63.625 65.3319C74.2669 61.7665 80.5813 53.1658 80.5541 42.3062C80.5269 31.7459 73.2327 22.3832 62.9446 19.6887C61.5293 19.3349 59.5969 19.0899 57.5012 19.0355C54.5073 18.9266 53.6635 19.0083 47.703 20.1514Z" fill="#6403FF"/>
                        <circle cx="56.26" cy="42.26" r="17.26" fill="white" id={`point`}/>
                        <defs>
                            <linearGradient id="paint0_linear_1271_407" x1="81" y1="44.5" x2="87.5" y2="44.5" gradientUnits="userSpaceOnUse">
                                <stop stopColor="white"/>
                                <stop offset="1" stopColor="white" stopOpacity="0"/>
                            </linearGradient>
                        </defs>
                    </svg>
                    <div className={styles.logoText}>
                        <img src={logoIntroText}/>
                    </div>
                </div>
            </div>

            <div className={styles.buttonsContainer}>
                <ButtonAccent
                    onClick={() => navigate('/login')}
                    title={'Войти'}
                    isLoading={false}
                    isEnabled={true}
                />
                <ButtonSecondary
                    onClick={() => navigate('/register')}
                    title={'Зарегистрироваться'}
                    buttonStyle={{marginTop: 10}}
                />
            </div>
        </div>
    );
}

export default IntroScreen;
