import {makeAutoObservable, runInAction} from 'mobx'
import {
	Async,
	AsyncState,
	failure,
	loading,
	success,
} from '../../../data/entity/Async'
import { Tag } from '../../../data/entity/Tag'
import { credentialManager } from '../../../data/repo/CredentialManager'
import { repository } from '../../../data/repo/Repository'
import { TagsInfo } from '../../../data/entity/TagsInfo'
import { Offer } from '../../../data/network/dto/OfferDto'

interface State {
	tags: TagsInfo
	offers: Async<OffersInfo>
	loadMore: AsyncState
	query: string | null
}

interface OffersInfo {
	offers: Offer[]
	offset: number
	hasMore: boolean
}

const OFFERS_STEP = 20
const OFFERS_INITIAL = 100
const SEARCH_DELAY_MS = 300

export class OffersViewModel {
	private tags: Async<Tag[]> = loading()
	private offers: Async<OffersInfo> = loading()
	private loadMore: AsyncState = AsyncState.SUCCESS
	private tag: Tag | null = null
	private query: string | null = null

	constructor() {
		makeAutoObservable(this)
		if (credentialManager.isLogged()) {
			this.onMount()
		}
	}

	onMount() {
		this.loadTags(true)
	}

	get state(): State {
		return {
			tags: {
				tags: this.tags.data ?? [],
				selectedTag: this.tag,
			},
			offers: this.offers,
			loadMore: this.loadMore,
			query: this.query,
		}
	}

	search(query: string | null) {
		if (query !== this.query) {
			this.query = query
			this.offers = loading()
            this.loadOffers()
                .catch(null);
		}
	}

	selectTag(tag: Tag) {
		this.offers = loading()
		this.tag = tag
		this.query = null
		this.loadOffers()
            .catch(null);
	}

	async loadMoreOffers() {
		if (!(this.offers.data?.hasMore ?? false)) return
		if (this.loadMore === AsyncState.LOADING) return
		this.loadMore = AsyncState.LOADING

        const offers = await repository
			.getOffers(
				OFFERS_STEP,
				this.offers.data?.offset ?? 0,
				this.tag?.key ?? null,
				this.query
			);

        runInAction(() => {
            if (offers) {
                this.offers = success({
                    offers: (this.offers.data?.offers ?? []).concat(offers),
                    offset: (this.offers.data?.offset ?? 0) + offers.length,
                    hasMore: offers.length >= OFFERS_STEP,
                })
                this.loadMore = AsyncState.SUCCESS;
            } else {
                this.loadMore = AsyncState.FAIL;
            }

        });
	}

	private async loadOffers() {
		const offers = await repository
			.getOffers(OFFERS_INITIAL, 0, this.tag?.key ?? null, this.query);

        runInAction(() => {
            if (offers) {
                this.offers = success({
                    offers: offers,
                    offset: OFFERS_INITIAL,
                    hasMore: offers.length >= OFFERS_STEP,
                });
            } else {
                this.offers = failure(null)
            }
        });
	}

	private loadTags(firstLoad: boolean) {
		repository
			.getTags()
			.then(tags => {
				this.buildTags(tags, firstLoad)
			})
			.catch(e => {
				console.log(e)
				this.buildTags([], firstLoad)
			})
	}

	private buildTags(tags: Tag[], firstLoad: boolean) {
		const allTag: Tag = {
			title: 'Все',
			key: null,
			count: 0,
		}
		tags.unshift(allTag)
		this.tags = success(tags)
		if (firstLoad) {
			this.selectTag(allTag)
		}
	}
}
