import {useEffect, useMemo} from "react";
import {RegisterViewModel} from "./RegisterViewModel";
import {Route, Routes, useNavigate} from "react-router-dom";
import RegisterScreen from "./RegisterScreen";
import {observer} from "mobx-react";
import RegisterCodeScreen from "./RegisterCodeScreen";
import {reaction} from "mobx";
import {AsyncState} from "../../data/entity/Async";
//import RegisterSuccessScreen from "./RegisterSuccessScreen";
import {showErrorSnack} from "../RootScreen";

const RegisterFlowScreen = () => {

    let navigate = useNavigate()
    const viewModel = useMemo(() => new RegisterViewModel(), [])

    useEffect(() => reaction(() => viewModel.registerRequest,
        request => {
            if (request.state === AsyncState.SUCCESS) {
                navigate('code')
            } else if (request.state === AsyncState.FAIL) {
                showErrorSnack(request.error)
            }
        }), [navigate, viewModel]);

    useEffect(() => reaction(() => viewModel.activateRequest,
        request => {
            if (request.state === AsyncState.FAIL) {
                showErrorSnack(request.error)
            }
        }), [navigate, viewModel]);

    return <div>
        <Routes>
            <Route index element={
                <RegisterScreen viewModel={viewModel}/>
            }/>
            <Route path='code' element={
                <RegisterCodeScreen viewModel={viewModel}/>
            }/>
        </Routes>
    </div>
}

export default observer(RegisterFlowScreen)
