import React, {CSSProperties, useEffect, useRef, useState} from "react";
import {useDebounce} from "../base/UseDebounce";
import styles from "./vertical_collection.module.css";

interface Props<T> {
    id: string,
    orientation: Orientation,
    items: T[]
    mapItem: (item: T) => JSX.Element,
    loadMore?: () => void,
    moreItem?: () => JSX.Element,
    containerStyle?: CSSProperties
}

export enum Orientation {
    VERTICAL,
    HORIZONTAL
}

export const CollectionView = <T,>({id, orientation, items, mapItem, loadMore, moreItem, containerStyle}: Props<T>) => {
    const scroller = useRef<HTMLDivElement>(null);
    const [scrollPosition, setScrollPosition] =  useState<number>(0)
    const debouncedValue = useDebounce<number>(scrollPosition, 500)

    useEffect(() => {
        if (scroller && scroller.current) {
            if (orientation === Orientation.VERTICAL) {
                scroller.current.scrollTo(0, +(sessionStorage.getItem(id) ?? '0'))
            } else {
                scroller.current.scrollTo(+(sessionStorage.getItem(id) ?? '0'), 0)
            }
        }
    }, [scroller])

    useEffect(() => {
        sessionStorage.setItem(id, `${debouncedValue}`)
    }, [debouncedValue]);

    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const scroll = orientation === Orientation.VERTICAL ? event.currentTarget.scrollTop : event.currentTarget.scrollLeft
        setScrollPosition(scroll)
        if (loadMore) {
            const { scrollTop, scrollHeight, clientHeight } = event.currentTarget
            if (scrollTop + clientHeight === scrollHeight) {
               loadMore()
            }
        }
    };

    return (
        <div
            className={ orientation === Orientation.VERTICAL ? styles.pageRootVertical : styles.pageRootHorizontal}
            style={containerStyle}
            onScroll={handleScroll}
            ref={scroller}>
            <ul style={ orientation === Orientation.HORIZONTAL ? {display:'flex'} : {}}>
                {items.map((item) => mapItem(item)).concat(moreItem?.() ?? <div key={Math.random()}/>)}
            </ul>
        </div>
    )
}
