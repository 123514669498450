import { getStringWithCents } from '../../data/network/dto/MoneyDto'
import { Product } from '../../data/network/dto/ProductDto'
import icBalance from '../../images/icons/ic_20_hide_balance.svg'
import icDiscount from '../../images/icons/ic_22_discount.svg'
import styles from './product_item.module.css'

interface Props {
	product: Product
	showCompensation: boolean
	onHideClick: () => void
	onClick: (product: Product) => void
}

export const ProductItem = ({product, showCompensation, onHideClick, onClick}:Props) => {
	const compensation = (): JSX.Element => {

		if (!showCompensation) {
			return (
				<div 
					className={styles.priceBlock}
					style={{paddingTop: '20px'}}
					onClick={(e) => {
						e.stopPropagation()
						onHideClick()
					}}
				>
					<img
						src={icBalance}
						className={styles.balance}
						alt={``}
					/>
				</div>
			)
		} else {
			return(
				<div className={styles.priceBlock}
				onClick={(e) => {
					e.stopPropagation()
					onHideClick()
				}}>
					<p className={styles.priceDescription}>{`Твой доход:`}</p>
					<p className={styles.compensation}>{`${getStringWithCents(product.compensation)} ${product.compensation.currency_symbol}`}</p>
				</div>
			)
		}
	}

	return (
		<li>
			<div className={styles.container}
			onClick={() => onClick(product)}>
					<div className={styles.logoBlock}>
						{product.image ? <img src={product.image} className={styles.logo} alt={``} /> : <div className={styles.logo}/>}
						{product.is_promo && <img src={icDiscount} className={styles.promo} alt={``} />}
					</div>
					<div className={styles.content}>
						<p className={styles.title}>{product.title}</p>
						<div className={styles.row}>
							<div className={styles.infoBlock}>
								<p className={styles.description}>{product.short_description}</p>
								<p className={styles.description}>Цена: <span style={{color:'#4B4B4B'}}>{`${getStringWithCents(product.price)} ${product.price.currency_symbol}`}</span></p>
							</div>
							{compensation()}
						</div>
					</div>
			</div>
		</li>
	)
}