import {useNavigate} from "react-router-dom";
import styles from './top.module.css'
import ic_back from "../../../images/icons/ic_20_back.svg";
import {observer} from "mobx-react";
import {useMemo} from "react";
import {TopSellersViewModel} from "./TopSellersViewModel";
import {TopSeller} from "../../../data/network/dto/TopSeller";
import {AsyncState} from "../../../data/entity/Async";
import {Loader} from "../../../components/loader/Loader";

const TopSellersScreen = () => {

    const navigate = useNavigate()
    const viewModel = useMemo(() => new TopSellersViewModel(), [])

    const navBar = () => {
        return <div className={styles.navbarContainer}>
            <div
                className={styles.closeContainer}
                onClick={() => {
                    navigate(-1)
                }}>
                <img
                    className={styles.iconClose}
                    src={ic_back}
                    alt={``}
                />
            </div>
            <p className={styles.barTitle}>Топ 10 продавцов</p>
        </div>
    }

    const sellerItem = (seller: TopSeller, index: number) => {
        let name = ''
        if (seller.is_current_user) name = 'Я'
        else {
            const length = seller.user_email.length
            try {
                name = `${seller.user_email.substring(0, 3)}...${seller.user_email.substring(length-3, length)}`
            } catch (e) {
                name = seller.user_email
            }
        }
        return (
            <li key={seller.user_id}>
                <div className={styles.itemRootContainer}>
                    <div className={seller.is_current_user ? styles.itemSelectedContainer : index % 2 !== 0 ? styles.itemWhiteContainer : styles.itemContainer}>
                        <p className={seller.is_current_user ? styles.positionSelected : styles.position}>{`${seller.position}`}</p>
                        <p className={seller.is_current_user ? styles.loginSelected : styles.login}>{name}</p>
                        <p className={seller.is_current_user ? styles.actionSelected : styles.action}>{`${seller.target_action_count}`}</p>
                        <p className={seller.is_current_user ? styles.amountSelected : styles.amount}>{0}</p>
                    </div>
                </div>
            </li>
        )
    }

    return (
        <div className={styles.pageRoot}>
            {navBar()}
            <div className={styles.contentContainer}>
                <div className={styles.tabs}>
                    <p className={styles.tab1}>Время</p>
                    <p className={styles.tab2}>Целевое действие</p>
                    <p className={styles.tab3}>Твой доход</p>
                </div>
                {viewModel.state.sellers.state === AsyncState.LOADING && <Loader/>}
                {viewModel.state.sellers.state === AsyncState.SUCCESS && (
                    <ul>
                        {viewModel.state.sellers.data?.map(sellerItem)}
                    </ul>
                )}
            </div>
        </div>
    )
}

export default observer(TopSellersScreen)
