import {PromocodeViewModel} from "./PromocodeViewModel";
import {useNavigate} from "react-router-dom";
import styles from './sell_promocode.module.css'
import {AsyncState} from "../../../../data/entity/Async";
import {Loader} from "../../../../components/loader/Loader";
import icRefresh from "../../../../images/icons/ic_18_refresh.svg";
import {SimpleNavBar} from "../../../../components/navbar/SimpleNavBar";
import ButtonAccent from "../../../../components/button/ButtonAccent";
import {share} from "../../../RootScreen";
import ButtonSecondary from "../../../../components/button/ButtonSecondary";
import {observer} from "mobx-react";

interface Props {
    viewModel: PromocodeViewModel
}

const SellPromocodeScreen = ({viewModel}:Props) => {

    let navigate = useNavigate()

    const content = () => {
        let state = viewModel.state.order.state
        if (state === AsyncState.LOADING) {
            return <Loader/>
        } else {
            return <div className={styles.contentContainer}>
                <p className={styles.title}>Оплата Промокода</p>
                <img src={viewModel.state.order.data?.qr_code} className={styles.qrCode}/>
                <p className={styles.subtitle}>Помоги клиенту отсканировать QR-код или отправь ссылку на оплату</p>

                <div className={styles.refreshContainer}>
                    {viewModel.state.request && <span className={styles.loader}/>}
                    {!viewModel.state.request
                        && <div
                            className={styles.refreshBlock}
                            onClick={() => {
                                viewModel.checkState()
                            }}>
                            <img src={icRefresh} className={styles.refreshIcon}/>
                            <p className={styles.refreshText}>Проверить оплату</p>
                        </div>}
                </div>
            </div>
        }
    }

    return (<div className={styles.pageRoot}>
        <SimpleNavBar title='Оплата Промокода'/>
        {content()}
        {viewModel.state.order.state !== AsyncState.LOADING
            && <div className={styles.buttonsContainer}>
                <ButtonAccent
                    title='Поделиться для оплаты'
                    onClick={() => {
                        share(viewModel.state.order.data?.qr_url)
                    }}
                    isLoading={false}
                    isEnabled={true}/>

                <ButtonSecondary
                    title='Закрыть'
                    onClick={() => {
                        navigate(-1)
                    }}/>
            </div>}
    </div>)
}

export default observer(SellPromocodeScreen)
