import {useLocation, useNavigate} from "react-router-dom";
import  styles from './order_error.module.css'
import {SimpleNavBar} from "../../../../components/navbar/SimpleNavBar";
import ButtonSecondary from "../../../../components/button/ButtonSecondary";


export const OrderErrorRevoked = 'revoked'
export const OrderErrorOfferRefunded= 'offer_refunded'
export const OrderErrorProductRefunded= 'product_refunded'
export const OrderErrorScreen = () => {

    let navigate = useNavigate()
    let location = useLocation()

    const description = () => {
        if (location.state.type === OrderErrorRevoked) {
            return 'Извините, не удалось обработать оплату.\nПопробуйте еще раз'
        } else if (location.state.type === OrderErrorOfferRefunded) {
            return 'Возникла ошибка при получении промокода. Деньги будут возвращены на счет клиента'
        } else if (location.state.type === OrderErrorProductRefunded) {
            return 'Возникла ошибка при обработке заказа. Деньги будут возвращены на счет клиента'
        } else {
            return 'Возникла ошибка'
        }
    }

    return <div className={styles.pageRoot}>
        <SimpleNavBar title='Ошибка'/>
        <div className={styles.contentContainer}>
            <p className={styles.subtitle}>{description()}</p>
        </div>
        <div className={styles.buttonsContainer}>
            <ButtonSecondary
                title='Закрыть'
                onClick={() => {
                    navigate(-1)
                }}/>
        </div>
    </div>

}
