import styles from "./profile.module.css"
import {ProfileViewModel} from "./ProfileViewModel";
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react";
import ic_avatar from "../../images/icons/ic_30_avatar.svg"
import {ProfileItem} from "../../components/profile_item/ProfileItem";
import {credentialManager} from "../../data/repo/CredentialManager";

interface Props {
    viewModel: ProfileViewModel
}

const ProfileScreen = ({viewModel}: Props) => {

    let navigate = useNavigate()

    return (
        <div className={styles.pageRoot}>
            <p className={styles.pageTitle}>{"Профиль"}</p>
            <div className={styles.topContainer}>
                <div className={styles.avatarContainer}>
                    <img src={ic_avatar} className={styles.avatar}/>
                </div>
                <div style={{flexGrow: 1}}/>
                <p className={styles.buttonExit} onClick={() => viewModel.logout()}>{"Выход"}</p>
            </div>
            <ProfileItem label={"ИМЯ КОМПАНИИ"}
                         text={viewModel.state.user?.company?.title ?? ''}/>
            <ProfileItem label={"EMAIL"}
                         text={viewModel.state.user?.email ?? ''}
                         itemStyle={{marginTop: '16px'}}/>
            <ProfileItem label={"ПАРОЛЬ"} text={"••••••••••••"}
                         itemStyle={{marginTop: '16px'}}
                         onClick={() => navigate('/password')}/>
            <ProfileItem label={"ID КОМПАНИИ"}
                         text={viewModel.state.user?.company?.code ?? ''}
                         itemStyle={{marginTop: '16px'}}/>
        </div>
    )
}

export default observer(ProfileScreen);
