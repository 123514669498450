import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { OffersViewModel } from '../../screens/offer/list/OffersViewModel'
import { useDebounce } from '../base/UseDebounce'
import { CollectionView, Orientation } from '../collection/VericalCollection'
import { SearchInput } from '../input/SearchInput'
import { TagItem } from '../tag/TagItem'
import styles from './offers_header_block.module.css'

interface Props {
	viewModel: OffersViewModel
}

const OffersHeaderBlock = ({ viewModel }: Props) => {
	let [query, setQuery] = useState<string | null>(viewModel.state.query)
	let debouncedValue = useDebounce<string | null>(query, 300)

	useEffect(() => {
		viewModel.search(debouncedValue)
	}, [debouncedValue])

	return (
		<div className={styles.container}>
			<SearchInput
				initQuery={viewModel.state.query ?? ''}
				onChange={text => viewModel.search(text)}
				containerStyle={{ paddingLeft: '16px', paddingRight: '16px' }}
			/>
			<CollectionView
				id={'tags_list'}
				orientation={Orientation.HORIZONTAL}
				items={viewModel.state.tags.tags}
				mapItem={item => (
					<TagItem
						key={item.title}
						tag={item}
						isSelectedTag={
							item.title === viewModel.state.tags.selectedTag?.title
						}
						onTagClick={tag => viewModel.selectTag(tag)}
					/>
				)}
				containerStyle={{
					paddingLeft: '14px',
					paddingRight: '14px',
					paddingTop: '8px',
				}}
			/>
		</div>
	)
}

export default observer(OffersHeaderBlock)
