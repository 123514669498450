import { CompensationInfo, Offer } from '../../data/network/dto/OfferDto'
import styles from "./offer_item.module.css"

interface Props {
    offer: Offer
    onClick: (offer: Offer) => void
}

export const OfferItem = ({offer, onClick}: Props) => {
    const compensation: CompensationInfo = calculateCompensations(offer)

    return <li>
        <div
            className={styles.container}
            onClick={() => onClick(offer)}>
            {offer.image ? <img src={offer.image} className={styles.logo} alt={``} /> : <div className={styles.logo}/>}
            <div className={styles.content}>
                <div className={styles.textContainer}>
                    <p className={styles.title}>{offer.title}</p>
                    <p className={styles.subtitle}>{offer.short_description}</p>
                </div>
                <p className={styles.price}>{
                    hasAdditionalCompensations(offer)
                    ? `${compensation.price} ${compensation.currency}\n + доп. комп.`
                    : `${compensation.price} ${compensation.currency}`
                }</p>
            </div>
        </div>
    </li>
}

export const hasAdditionalCompensations = ({compensations}: Offer): boolean => {
    return compensations.filter((compensation) => compensation.type === 'additional').length > 0
}

export const calculateCompensations = ({compensations}: Offer): CompensationInfo => {
    let price = 0;
    let revPrice = 0
    let currency = ""
    let revCurrency = ""

    compensations
        .filter((compensation) => compensation.type === 'base')
        .forEach((compensation) => {
            price += compensation.cost?.value / 100
            revPrice += compensation?.rev
            currency = compensation.cost.currency_symbol
            revCurrency = compensation.rev_currency
        })

    if (revPrice > 0) {
        return {
            currency: revCurrency,
            price: revPrice
        }
    } else {
        return {
            currency: currency,
            price: price
        }
    }
}
