import {OrderType, ProductOrderViewModel} from "./ProductOrderViewModel";
import {Route, Routes, useLocation, useNavigate, useParams} from "react-router-dom";
import {useEffect, useMemo} from "react";
import {observer} from "mobx-react";
import styles from './product_order.module.css'
import {ProductOrderSuccessScreen} from "./success/ProductOrderSuccessScreen";
import {reaction} from "mobx";
import {AsyncState} from "../../../data/entity/Async";
import ProductOrderPayScreen from "./pay/ProductOrderPayScreen";
import {ProductFeedbackScreen} from "./feedback/ProductFeedbackScreen";
import {OrderErrorProductRefunded, OrderErrorRevoked, OrderErrorScreen} from "../../offer/order/error/OrderErrorScreen";

interface Props {
    type: OrderType
}
const ProductOrderScreen = ({type}:Props) => {

    let navigate = useNavigate()
    let location = useLocation()
    let {orderId} = useParams()
    let viewModel = useMemo(() => new ProductOrderViewModel(orderId!, type), [])

    useEffect(
        () => reaction( () => viewModel.order,
            order => {
                if (order.state === AsyncState.SUCCESS) {
                    let status = order.data?.status!
                    if ((status === 'in_process' || status === 'new') && !location.pathname.endsWith(orderId!)) {
                        navigate(-1)
                    } else if (status === 'success' && !location.pathname.endsWith('success')) {
                        navigate('success', {
                            replace: true
                        })
                    } else if (status === 'revoked' || status === 'error') {
                        navigate('error', {
                            state: {
                                type: OrderErrorRevoked
                            },
                            replace: true
                        })
                    } else if (status === 'refunded') {
                        navigate('error', {
                            state: {
                                type: OrderErrorProductRefunded
                            },
                            replace: true
                        })
                    }
                } else if (order.state === AsyncState.FAIL) {

                }
            }),
        []
    )

    return (<div className={styles.pageRoot}>
        <Routes>
            <Route index element={
                <ProductOrderPayScreen viewModel={viewModel}/>
            }/>
            <Route path='success' element={
                <ProductOrderSuccessScreen viewModel={viewModel}/>
            } />
            <Route path='feedback' element={
                <ProductFeedbackScreen feedback={viewModel.state.order.data?.feedback}/>
            } />
            <Route path='error' element={
                <OrderErrorScreen/>
            }/>
        </Routes>
    </div>)

}

export default observer(ProductOrderScreen)
