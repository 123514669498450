import {CSSProperties, useState} from "react";
import styles from "./input.module.css";

const InputPassword = ({onChange,placeholder, inputStyle}: {onChange: (text: string) => void,placeholder?: string, inputStyle?: CSSProperties}) => {
    const [password, setPassword] = useState('');

    return (
        <input
            type='password'
            className={styles.input}
            style={inputStyle}
            placeholder={placeholder ?? 'Пароль'}
            value={password}
            onChange={ event => {
                const text = event.target.value
                onChange(text)
                setPassword(text)
            }}
        />
    )
}

export default InputPassword
