import { makeAutoObservable } from 'mobx'
import {Async, failure, initial, loading, success} from '../../../data/entity/Async'
import { ProductFull } from '../../../data/network/dto/ProductDto'
import { preferences } from '../../../data/repo/Preferences'
import { repository } from '../../../data/repo/Repository'
import { ProductOrder } from '../../../data/network/dto/PaymentDto'

interface State {
	productFull: Async<ProductFull>,
	referralLink: Async<string>,
	isCompensationVisible: boolean,
	order: Async<ProductOrder>,
}

export class ProductDetailsViewModel {
	private productId: string = ''
	private productFull: Async<ProductFull> = loading()
	referralLink: Async<string> = initial()
	private isCompensationVisible: boolean = preferences.isCompensationVisible()
	productOrder: Async<ProductOrder> = initial()

	constructor(productId: string) {
		this.productId = productId
		makeAutoObservable(this)
		this.getProduct()
	}

	get state(): State {
		return {
			productFull: this.productFull,
			referralLink: this.referralLink,
			isCompensationVisible: this.isCompensationVisible,
			order: this.productOrder
		}
	}

	private getProduct() {
		repository.getProduct(this.productId)
		.then((productFull) => {
                this.productFull = success(productFull)
            })
            .catch((e) => {
							this.productFull = failure(e)
                console.log(e)
            })
	}

	toggleCompensations() {
		let isVisible = preferences.isCompensationVisible()
		preferences.setCompensationVisibility(!isVisible)
		this.isCompensationVisible = !isVisible
	}

	loadReferralLink() {
		this.referralLink = loading()
		repository.getProductReferralLink(this.productId)
		.then((response) => {
				this.referralLink = success(response.link.url)
		})
		.catch((e) => {
			console.log(e)
			this.referralLink = failure(e)
		})
	}

	createOrder() {
		this.productOrder = loading()
		 repository.createProductOrder(this.productId)
		  .then((response) => {
			this.productOrder = success(response)
	    })
	   .catch((e) => {
		 console.log(e)
		 this.productOrder = failure(e)
	  })
	}
}
