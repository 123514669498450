import {computed, makeObservable, observable, runInAction} from 'mobx'
import {Async, AsyncState, failure, loading, success} from '../../../data/entity/Async'
import { FilterInfo } from '../../../data/entity/FilterInfo'
import { ProductSort } from '../../../data/entity/ProductSort'
import { Tag } from '../../../data/entity/Tag'
import { TagsInfo } from '../../../data/entity/TagsInfo'
import { credentialManager } from '../../../data/repo/CredentialManager'
import { preferences } from '../../../data/repo/Preferences'
import { repository } from '../../../data/repo/Repository'
import { Product } from '../../../data/network/dto/ProductDto'
import {eventBus, subscribe} from "../../../data/utils/EventBus";
import {ApplyFilter} from "../../../data/utils/Events";

interface State {
	tags: TagsInfo
	products: Async<ProductsInfo>
	loadMore: AsyncState
	query: string | null
	filterInfo: FilterInfo
	showCompensation: boolean
	sort: ProductSort
}

interface ProductsInfo {
	products: Product[],
	offset: number
	hasMore: boolean
}

const PRODUCTS_STEP = 20
const PRODUCTS_INITIAL = 100
const SEARCH_DELAY_MS = 300

export class ProductsViewModel {
	tags: Async<Tag[]> = loading()
	products: Async<ProductsInfo> = loading()
	loadMore: AsyncState = AsyncState.SUCCESS
	tag: Tag | null = null
	query: string | null = null
	filterInfo: FilterInfo = {
		isPromo: null,
		platforms: null,
		publishers: null,
		activationPlatforms: null,
		releaseYears: null
	}
	showCompensation: boolean = preferences.isCompensationVisible()
	sort: ProductSort = ProductSort.NONE
	private abortController: AbortController = new AbortController()

	constructor() {
		makeObservable(this,{
				tags: observable,
				products: observable,
				loadMore: observable,
				filterInfo: observable,
				sort: observable,
				tag: observable,
				showCompensation: observable,
				state: computed,
		})
		if (credentialManager.isLogged()) {
			this.onMount()
		}
		eventBus.register(this)
	}

	onMount() {
		this.loadTags(true)
	}

	get state(): State {
		return {
			tags: {
				tags: this.tags.data ?? [],
				selectedTag: this.tag,
			},
			products: this.products,
			loadMore: this.loadMore,
			query: this.query,
			filterInfo: this.filterInfo,
			showCompensation: this.showCompensation,
			sort: this.sort
		}
	}

	toggleCompensations() {
		let isVisible = preferences.isCompensationVisible()
		preferences.setCompensationVisibility(!isVisible)
		this.showCompensation = !isVisible
	}

	search(query: string | null) {
		if (query !== this.query) {
			this.abortController.abort()
			this.query = query
			this.products = loading()
			this.loadProducts()
		}
	}

	selectTag(tag: Tag) {
		this.abortController.abort()
		this.products = loading()
		this.tag = tag
		this.query = null
		this.loadProducts()
	}

    setSort(sortType: ProductSort) {
        if (this.sort === sortType) return;
        this.sort = sortType;
        this.loadProducts();
    }

	loadMoreProducts() {
		this.abortController = new AbortController()
		if (!(this.products.data?.hasMore ?? false)) return
		if (this.loadMore === AsyncState.LOADING) return
		this.loadMore = AsyncState.LOADING
		repository
			.getProducts(
				PRODUCTS_STEP,
				this.products.data?.offset ?? 0,
				this.tag?.key ?? null,
				this.query,
				this.filterInfo,
				this.sort,
				this.abortController.signal
			)
			.then(products => {
				this.products = success({
					products: (this.products.data?.products ?? []).concat(products),
					offset: (this.products.data?.offset ?? 0) + products.length,
					hasMore: products.length >= PRODUCTS_STEP,
				})
				this.loadMore = AsyncState.SUCCESS
			})
			.catch(() => {
				this.loadMore = AsyncState.FAIL
			})
	}

	private loadProducts() {
		this.abortController = new AbortController()
		repository.getProducts(
			PRODUCTS_INITIAL,
			0,
			this.tag?.key ?? null,
			this.query,
			this.filterInfo,
			this.sort,
			this.abortController.signal
		)
		.then(products => {
			this.products = success({
				products: products,
				offset: PRODUCTS_INITIAL,
				hasMore: products.length >= PRODUCTS_STEP
			})
		})
		.catch(() => {
			this.products = failure(null)
		})
	}

	@subscribe(ApplyFilter)
	private onFilterApply(event: any) {
		this.filterInfo = event.payload
		this.abortController.abort()
		this.loadProducts()
	}

	private loadTags(firstLoad: boolean) {
		repository
			.getProductTags()
			.then(tags => {
				this.buildTags(tags, firstLoad)
			})
			.catch(e => {
				console.log(e)
				this.buildTags([], firstLoad)
			})
	}

	private buildTags(tags: Tag[], firstLoad: boolean) {
		const allTag: Tag = {
			title: 'Все',
			key: null,
			count: 0,
		}
		tags.unshift(allTag)

        const nextTags = success(tags);
        runInAction(() => {
            this.tags = nextTags;
            if (firstLoad) {
                this.selectTag(allTag);
            }
        });
	}
}
