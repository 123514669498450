import {Route, Routes, useParams} from "react-router-dom";
import React, {useMemo} from "react";
import {OfferDetailsViewModel} from "./OfferDetailsViewModel";
import {CheckAuth} from "../../../data/utils/CheckAuth";
import OfferDetailsScreen from "./OfferDetailsScreen";
import {PlainPromocodeScreen} from "../order/plain/PlainPromocodeScreen";
import SellPromocodeFlowScreen from "../order/sell/SellPromocodeFlowScreen";
import ProductOrderScreen from "../../products/order/ProductOrderScreen";
import {OrderType} from "../../products/order/ProductOrderViewModel";

export const OfferFlowScreen = () => {

    let {offerId} = useParams()
    let viewModel = useMemo(() => new OfferDetailsViewModel(offerId!), [])

    return <div>
        <Routes>
            <Route index element={
                <CheckAuth>
                    <OfferDetailsScreen viewModel={viewModel}/>
                </CheckAuth>
            }/>
            <Route path='promocode/:orderId/*' element={
                <CheckAuth>
                    <SellPromocodeFlowScreen/>
                </CheckAuth>
            }/>
            <Route path='promocode_sell/:orderId/*' element={
                <CheckAuth>
                    <ProductOrderScreen type={OrderType.SELL_PROMOCODE}/>
                </CheckAuth>
            }/>
            <Route path='promocode_share/:orderId/*' element={
                <CheckAuth>
                    <ProductOrderScreen type={OrderType.SHARE_PROMOCODE}/>
                </CheckAuth>
            }/>
            <Route path='plain' element={
                <CheckAuth>
                    <PlainPromocodeScreen/>
                </CheckAuth>
            }/>
        </Routes>
    </div>
}
