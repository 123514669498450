import styles from './catalog.module.css'
import {CatalogViewModel} from "./CatalogViewModel";
import {observer} from "mobx-react";
import CatalogTabs, {CatalogTab} from "../../components/tabs/CatalogTabs";
import {OffersViewModel} from "../offer/list/OffersViewModel";
import OffersScreen from "../offer/list/OffersScreen";
import OffersHeaderBlock from "../../components/offers/OffersHeaderBlock";
import ProductsScreen from '../products/list/ProductsScreen'
import { ProductsViewModel } from '../products/list/ProductsViewModel'

interface Props {
    viewModel: CatalogViewModel
    offersViewModel: OffersViewModel
    productsViewModel: ProductsViewModel
}

const CatalogScreen = ({viewModel, offersViewModel, productsViewModel}: Props) => {

    return (
        <div className={styles.pageRoot}>
            {
                viewModel.state.isProductsEnabled &&
                viewModel.state.isOffersEnabled &&
                <div className={styles.headerContainer}>
                    <CatalogTabs
                        activeTab={viewModel.state.selectedTab}
                        onTabClick={(tab) => {
                            viewModel.selectTab(tab)
                        }}/>
                </div>
            }
            <div className={styles.contentContainer}>
                {viewModel.state.selectedTab === CatalogTab.OFFERS && <OffersScreen viewModel={offersViewModel}/>}
                {viewModel.state.selectedTab === CatalogTab.PRODUCTS && <ProductsScreen viewModel={productsViewModel}/>}
            </div>
        </div>
    )
}

export default observer(CatalogScreen);
