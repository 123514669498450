import {StatisticViewModel} from "./StatisticViewModel";
import styles from './statistic.module.css'
import {MonthsTabs} from "../../components/tabs/MonthsTabs";
import icTop from '../../images/icons/ic_16_top_sellers.svg'
import icCalendarActive from '../../images/icons/ic_24_calendar_active.svg.svg'
import icCalendarInactive from '../../images/icons/ic_24_calendar_inactive.svg'
import {observer} from "mobx-react";
import {StatisticOfferItem} from "../../components/statistic/StatisticOfferItem";
import {useNavigate} from "react-router-dom";

interface Props {
    viewModel: StatisticViewModel
}

const StatisticScreen = ({viewModel}: Props) => {

    const isCustomRange = viewModel.state.range.type === 'custom'
    let navigate = useNavigate()

    const revenueBlock = () => {
        let range = viewModel.state.range
        let revenues = viewModel.state.revenues
        let total: string = revenues
            .map((revenue) => `${revenue.sum} ${revenue.currency}`)
            .join('\n')

        return (
            <div className={styles.revenueContainer}>
                <p className={styles.revenueTitle}>{`Заработано за ${range.type === 'custom'
                    ? range.range.nameFull.toLowerCase()
                    : range.range.name.toLowerCase()}:`}</p>
                <p className={styles.revenue}>{total === '' ? '0' : total}</p>
                <div className={styles.topContainer} onClick={() =>{
                    navigate('top')
                }}>
                    <p className={styles.topText}>{`Топ 10 продавцов`}</p>
                    <img
                        src={icTop}
                        className={styles.topIcon}
                        alt={``}
                    />
                </div>
            </div>
        )
    }

    return <div className={styles.pageRoot}>
        <div className={styles.headerContainer}>
            <MonthsTabs
                activeRange={viewModel.state.range}
                onRangeClick={(range) => viewModel.loadStatistic(range)}/>
            <div
                className={isCustomRange ? styles.calendarActive : styles.calendarInactive}
                onClick={() => navigate('ranges')}>
                <img
                    src={isCustomRange ? icCalendarActive : icCalendarInactive}
                    className={styles.iconCalendar}
                    alt={``}
                />
            </div>
        </div>
        <div className={styles.contentContainer}>
            <ul>
                <li>
                    {revenueBlock()}
                </li>
                {viewModel.state.offers.data?.map((offer) => (
                    <StatisticOfferItem
                        key={offer.offer_id}
                        offer={offer}
                        onClick={(id) => {
                            navigate(`details/${id}`, {
                                state: {
                                    title: offer.offer_title,
                                    start: viewModel.state.range.range.startDate,
                                    end: viewModel.state.range.range.endDate,
                                }
                            })
                        }}
                    />
                ))}
            </ul>
        </div>
    </div>
}

export default observer(StatisticScreen)
