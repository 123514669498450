import {useNavigate} from "react-router-dom";
import {useState} from "react";
import {RegisterViewModel} from "./RegisterViewModel";
import {observer} from "mobx-react";
import LoginHeader from "../../components/login_header/LoginHeader";
import styles from './register.module.css'
import InputEmail from "../../components/input/InputEmail";
import InputPassword from "../../components/input/InputPassword";
import ButtonAccent from "../../components/button/ButtonAccent";
import {AsyncState} from "../../data/entity/Async";
import {InputText} from "../../components/input/InputText";

interface Props {
    viewModel: RegisterViewModel
}
const RegisterScreen = ({viewModel}: Props) => {

    const navigate = useNavigate()
    const [company, setCompany] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    return <div>
        <LoginHeader onBackClick={() => navigate(-1)} title={`Активация`}/>
        <div className={styles.container}>
            <p className={styles.description}>{'Активация аккаунта продавца'}</p>
            <InputText
                placeholder='Код активации (ID компании)'
                onChange={text => setCompany(text)}/>
            <InputText
                inputStyle={{marginTop: 12}}
                placeholder='Email'
                onChange={text => setEmail(text)}/>

            <InputPassword
                inputStyle={{marginTop: 12}}
                onChange={text => setPassword(text)}/>

            <ButtonAccent
                buttonStyle={{marginTop: 32}}
                onClick={() => viewModel.register(company, email, password)}
                title={'Далее'}
                isLoading={viewModel.state.registerRequest.isLoading}
                isEnabled={Boolean(email.length && company.length && password.length)}/>

            <p className={styles.legal}>{'Нажимая кнопку «Далее», Вы соглашаетесь с Правилами использования сервиса, Политикой конфидециальности и Договором-Офертой'}</p>
        </div>
    </div>
}

export default observer(RegisterScreen)
