import {OrderType, ProductOrderViewModel} from "../ProductOrderViewModel";
import styles from './product_order_success.module.css'
import {SimpleNavBar} from "../../../../components/navbar/SimpleNavBar";
import icRefresh from "../../../../images/icons/ic_18_refresh.svg";
import {useNavigate} from "react-router-dom";
import {AsyncState} from "../../../../data/entity/Async";
import {Loader} from "../../../../components/loader/Loader";
import ButtonAccent from "../../../../components/button/ButtonAccent";
import ButtonSecondary from "../../../../components/button/ButtonSecondary";
import {share} from "../../../RootScreen";

interface Props {
    viewModel: ProductOrderViewModel
}
export const ProductOrderSuccessScreen = ({viewModel}: Props) => {

    let navigate = useNavigate()

    const contentProduct = () => {
        let state = viewModel.state.order.state
        if (state === AsyncState.LOADING) {
            return <Loader/>
        } else {
            return <div className={styles.contentContainer}>
                <p className={styles.titleDescription}>Оплата прошла, заказ отправлен</p>
                <p className={styles.title}>Номер заказа</p>
                <p className={styles.number}>{viewModel.state.order.data?.number}</p>
                <p className={styles.subtitle}>Если клиент не получил заказ, попробуй отправить повторно или направь
                    клиента на форму обратной связи</p>

                <div className={styles.refreshContainer}>
                    {viewModel.state.sendKeyRequest.state === AsyncState.LOADING && <span className={styles.loader}/>}
                    {viewModel.state.sendKeyRequest.state !== AsyncState.LOADING
                        && <div
                            className={styles.refreshBlock}
                            onClick={() => {
                                viewModel.sendEmailAgain()
                            }}>
                            <img src={icRefresh} className={styles.refreshIcon}/>
                            <p className={styles.refreshText}>Отправить ключ повторно</p>
                        </div>}
                </div>
            </div>
        }
    }

    const contentSellPromocode = () => {
        let state = viewModel.state.order.state
        if (state === AsyncState.LOADING) {
            return <Loader/>
        } else {
            return <div className={styles.contentContainer}>
                <p className={styles.titleDescription}>Оплата прошла, заказ отправлен</p>
                <p className={styles.title}>Номер заказа</p>
                <p className={styles.number}>{viewModel.state.order.data?.number}</p>
                <p className={styles.subtitle}>Если клиент не получил заказ, попробуй отправить повторно или направь
                    клиента на форму обратной связи</p>

                <div className={styles.refreshContainer}>
                    {viewModel.state.sendKeyRequest.state === AsyncState.LOADING && <span className={styles.loader}/>}
                    {viewModel.state.sendKeyRequest.state !== AsyncState.LOADING
                        && <div
                            className={styles.refreshBlock}
                            onClick={() => {
                                viewModel.sendEmailAgain()
                            }}>
                            <img src={icRefresh} className={styles.refreshIcon}/>
                            <p className={styles.refreshText}>Отправить заказ повторно</p>
                        </div>}
                </div>
            </div>
        }
    }

    const contentSharePromocode = () => {
        let state = viewModel.state.order.state
        if (state === AsyncState.LOADING) {
            return <Loader/>
        } else {
            return <div className={styles.contentContainer}>
                <p className={styles.title}>Код отправлен на email</p>
                <p className={styles.subtitle}>Если клиент не получил заказ, попробуй отправить повторно или направь
                    клиента на форму обратной связи</p>

                <div className={styles.refreshContainer}>
                    {viewModel.state.sendKeyRequest.state === AsyncState.LOADING && <span className={styles.loader}/>}
                    {viewModel.state.sendKeyRequest.state !== AsyncState.LOADING
                        && <div
                            className={styles.refreshBlock}
                            onClick={() => {
                                viewModel.sendEmailAgain()
                            }}>
                            <img src={icRefresh} className={styles.refreshIcon}/>
                            <p className={styles.refreshText}>Отправить повторно</p>
                        </div>}
                </div>
            </div>
        }
    }

    const content = () => {
      if (viewModel.state.type === OrderType.PRODUCT) {
          return contentProduct()
      } else if (viewModel.state.type === OrderType.SELL_PROMOCODE) {
          return contentSellPromocode()
      }else if (viewModel.state.type === OrderType.SHARE_PROMOCODE) {
          return contentSharePromocode()
      }
    }

    return (
        <div className={styles.pageRoot}>
            <SimpleNavBar title='Заказ'/>

            {content()}

            {viewModel.state.order.state !== AsyncState.LOADING
                && <div className={styles.buttonsContainer}>
                    {viewModel.state.type !== OrderType.SHARE_PROMOCODE
                    && <ButtonAccent
                            title='Поделиться номером заказа'
                            onClick={() => {
                                share(viewModel.state.order.data?.number)
                            }}
                            isLoading={false}
                            isEnabled={true}/>}

                <ButtonAccent
                    title='Форма обратной связи'
                    onClick={() => {
                        navigate('../feedback')
                    }}
                    isLoading={false}
                    isEnabled={true}/>

                <ButtonSecondary
                    title='Закрыть'
                    onClick={()=> {
                        navigate(-1)
                    }}/>
            </div>}
        </div>
    )
}
