import styles from "./offer_navbar.module.css"
import ic_back from "../../images/icons/ic_24_back.svg";
import {CSSProperties} from "react";

interface Props {
    title: string
    logo: string | undefined
    onBackClick: () => void,
    style?: CSSProperties
}

export const OfferNavBar = ({title, logo, onBackClick, style}: Props) => {
    return (
        <div
            className={styles.container}
            style={style}>
            <div
                className={styles.backContainer}
                onClick={() => {
                    onBackClick()
                }}>
                <img
                    className={styles.iconBack}
                    src={ic_back}/>
            </div>
            {logo ? <img src={logo} className={styles.logo}/> : <div className={styles.logo}/>}
            <p className={styles.title}>{title}</p>
        </div>
    )
}
