import styles from "./catalog_tabs.module.css";
import {StatisticRange} from "../../screens/statistic/StatisticViewModel";
import {timeUtil} from "../../data/utils/TimeUtil";

interface Props {
    activeRange: StatisticRange,
    onRangeClick: (range: StatisticRange) => void
}

export const MonthsTabs = ({activeRange, onRangeClick}: Props) => {
    return <div className={styles.tabsContainer}>
        <div
            className={activeRange.type === 'current' ? styles.tabActive : styles.tabNotActive}
            onClick={() => {
                onRangeClick({
                    type: 'current',
                    range: timeUtil.currentMonthRange()
                })
            }}>
            <p className={activeRange.type === 'current' ? styles.textActive : styles.textNotActive}>{timeUtil.currentMonthRange().name}</p>
        </div>
        <div
            className={activeRange.type === 'previous' ? styles.tabActive : styles.tabNotActive}
            onClick={() => {
                onRangeClick({
                    type: 'previous',
                    range: timeUtil.previousMonthRange()
                })
            }}>
            <p className={activeRange.type === 'previous' ? styles.textActive : styles.textNotActive}>{timeUtil.previousMonthRange().name}</p>
        </div>
    </div>
}
