export interface FilterInfo {
    isPromo: boolean | null,
    platforms: string[] | null,
    publishers: string[] | null,
    activationPlatforms: string[] | null,
    releaseYears: string[] | null,
}

export const hasActiveFilters = (filter: FilterInfo) : boolean => {
    if (filter.isPromo != null) return true
    if (filter.platforms?.length) return true
    if (filter.publishers?.length) return true
    if (filter.releaseYears?.length) return true
    if (filter.activationPlatforms?.length) return true
    return false
}

export const getActiveFilters = (filter: FilterInfo) : number => {
    let count = 0;
    if (filter.isPromo != null) count++
    if (filter.platforms?.length) count++
    if (filter.publishers?.length) count++
    if (filter.releaseYears?.length) count++
    if (filter.activationPlatforms?.length) count++
    return count
}
