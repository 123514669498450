export interface Money {
	value: number,
	currency: string,
	currency_symbol: string,
}

export const getStringWithCents = (money: Money) => {
	let cents = money.value % 100
	let sum = money.value / 100
	if (cents > 9) {
		return `${sum}.${cents}`
	} else if (cents > 0) {
		return `${sum}.0${cents}`
	} else {
		return `${sum}`
	}
}