import styles from "./profile_item.module.css"
import {CSSProperties} from "react";
interface Params {
    label: string
    text: string
    onClick?: () => void
    itemStyle?: CSSProperties
}
export const ProfileItem = ({label, text, onClick, itemStyle}:Params) => {
  return (
      <div className={styles.root} onClick={() => onClick?.()} style={itemStyle}>
          <p className={styles.label}>{label}</p>
          <p className={styles.text}>{text}</p>
          <img className={styles.line}/>
      </div>
  )
}
