import styles from './input.module.css'
import {useState} from "react";

const InputEmail = ({onChange}: {onChange: (text: string) => void}) => {

    const [email, setEmail] = useState('');

    return (
        <input
            className={styles.input}
            placeholder='Email'
            value={email}
            onChange={ event => {
                const text = event.target.value
                onChange(text)
                setEmail(text)
            }}
        />
    )
}

export default InputEmail
