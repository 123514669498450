import styles from "./main.module.css";
import BottomBar, {BottomItem} from "../../components/bottom_bar/BottomBar";
import {MainViewModel} from "./MainViewModel";
import CatalogScreen from "../catalog/CatalogScreen";
import {CatalogViewModel} from "../catalog/CatalogViewModel";
import {observer} from "mobx-react";
import {OffersViewModel} from "../offer/list/OffersViewModel";
import ProfileScreen from "../profile/ProfileScreen";
import {ProfileViewModel} from "../profile/ProfileViewModel";
import { ProductsViewModel } from '../products/list/ProductsViewModel'
import {StatisticViewModel} from "../statistic/StatisticViewModel";
import StatisticScreen from "../statistic/StatisticScreen";

interface Props {
    viewModel: MainViewModel
    catalogViewModel: CatalogViewModel
    offersViewModel: OffersViewModel
    productsViewModel: ProductsViewModel
    profileViewModel: ProfileViewModel,
    statisticViewModel: StatisticViewModel,
}

const MainScreen = ({viewModel, catalogViewModel, offersViewModel, profileViewModel, productsViewModel, statisticViewModel}: Props) => {

    return (
        <div className={styles.pageRoot}>
            {viewModel.state.selectedItem === BottomItem.CATALOG && <CatalogScreen viewModel={catalogViewModel} offersViewModel={offersViewModel}
            productsViewModel={productsViewModel}/>}
            {viewModel.state.selectedItem === BottomItem.PROFILE && <ProfileScreen viewModel={profileViewModel}/>}
            {viewModel.state.selectedItem === BottomItem.STATISTIC && <StatisticScreen viewModel={statisticViewModel}/>}
            <div className={styles.bottomContainer}>
                <BottomBar activeItem={viewModel.state.selectedItem} onItemClick={(item) => { viewModel.selectItem(item) }}/>
            </div>
        </div>
    );
}

export default observer(MainScreen);
