import {Route, Routes, useNavigate} from "react-router-dom";
import {useEffect, useMemo} from "react";
import {ResetViewModel} from "./ResetViewModel";
import {observer} from "mobx-react";
import {reaction} from "mobx";
import {AsyncState} from "../../data/entity/Async";
import {showErrorSnack} from "../RootScreen";
import ResetScreen from "./ResetScreen";
import ResetCodeScreen from "./ResetCodeScreen";

const ResetFlowScreen = () => {

    let navigate = useNavigate()
    const viewModel = useMemo(() => new ResetViewModel(), [])

    useEffect(() => reaction(() => viewModel.codeRequest,
        request => {
            if (request.state === AsyncState.SUCCESS) {
                navigate('code')
            } else if (request.state === AsyncState.FAIL) {
                showErrorSnack(request.error)
            }
        }), []);

    useEffect(() => reaction(() => viewModel.resetRequest,
        request => {
            if (request.state === AsyncState.FAIL) {
                showErrorSnack(request.error)
            }
        }), []);

    return <div>
        <Routes>
            <Route index element={
                <ResetScreen viewModel={viewModel}/>
            }/>
            <Route path='code' element={
                <ResetCodeScreen viewModel={viewModel}/>
            }/>
        </Routes>
    </div>
}

export default observer(ResetFlowScreen)
