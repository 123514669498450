import styles from "./more_loading.module.css";
import refresh from "../../images/icons/ic_30_refresh.svg";

interface Props {
    onRefresh: () => void
}

export const MoreErrorItem = ({onRefresh}: Props) => {
    return (
        <div className={styles.container}>
            <img src={refresh} className={styles.icon} onClick={() => onRefresh()}/>
        </div>
    )
}
