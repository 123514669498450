import {RegisterViewModel} from "../register/RegisterViewModel";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import LoginHeader from "../../components/login_header/LoginHeader";
import styles from "../register/register.module.css";
import {InputText} from "../../components/input/InputText";
import ButtonAccent from "../../components/button/ButtonAccent";
import {observer} from "mobx-react";
import {ResetViewModel} from "./ResetViewModel";
import InputPassword from "../../components/input/InputPassword";

interface Props {
    viewModel: ResetViewModel
}

const ResetCodeScreen = ({viewModel}: Props) => {

    const navigate = useNavigate()
    const [code, setCode] = useState('');
    const [password, setPassword] = useState('');
    const [repassword, setRepassword] = useState('');

    return <div>
        <LoginHeader onBackClick={() => navigate(-1)} title={`Восстановление\nпароля`}/>
        <div className={styles.container}>
            <p className={styles.description}>{'На указанный Вами адрес электронной почты был выслан код. Введите его в поле ниже'}</p>
            <InputText
                placeholder='Код'
                onChange={text => setCode(text)}/>

            <InputPassword
                inputStyle={{marginTop: 12}}
                onChange={text => setPassword(text)}/>

            <InputPassword
                inputStyle={{marginTop: 12}}
                onChange={text => setRepassword(text)}/>

            <ButtonAccent
                buttonStyle={{marginTop: 32}}
                onClick={() => viewModel.reset(code, password)}
                title={'Сбросить пароль'}
                isLoading={viewModel.state.resetRequest.isLoading}
                isEnabled={Boolean(code.length && password.length && password === repassword)}/>
        </div>
    </div>
}

export default observer(ResetCodeScreen)
