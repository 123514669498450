import {useCallback, useState} from "react";
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { ProductsViewModel } from './ProductsViewModel'
import {Product} from "../../../data/network/dto/ProductDto";
import { AsyncState } from '../../../data/entity/Async'
import { MoreLoadingItem } from '../../../components/more/MoreLoadingItem'
import { MoreErrorItem } from '../../../components/more/MoreErrorItem'
import styles from './products.module.css'
import ProductsHeaderBlock from '../../../components/products/ProductsHeaderBlock';
import BottomMenu from "../../../components/bottom_menu/ButtonMenu";
import { CollectionView, Orientation } from '../../../components/collection/VericalCollection'
import { ProductItem } from '../../../components/product/ProductItem'

import sortIcon from "../../../images/icons/ic_20_sort.svg";
import sortAscIcon from "../../../images/icons/ic_20_arrow_up.svg";
import sortDescIcon from "../../../images/icons/ic_20_arrow_down.svg";
import showBalanceIcon from "../../../images/icons/ic_16_eye.svg";
import hideBalanceIcon from "../../../images/icons/ic_16_eye_no.svg";
import currentSortIcon from "../../../images/icons/ic_20_approve.svg";

import {ProductSort} from "../../../data/entity/ProductSort";

interface Props {
	viewModel: ProductsViewModel
}

const sortIcons = {
    [ProductSort.PRICE_ASC]: sortAscIcon,
    [ProductSort.PRICE_DESC]: sortDescIcon,
    [ProductSort.NONE]: sortIcon
}

const sortLabels = {
    [ProductSort.PRICE_ASC]: `Дешевые`,
    [ProductSort.PRICE_DESC]: `Дорогие`,
    [ProductSort.NONE]: `Сортировать`
}

const ProductsScreen = ({viewModel}: Props) => {
	const navigate = useNavigate();
    const [isSortOpen, setSortOpen] = useState<boolean>(false);

    const moreItem = (state: AsyncState): JSX.Element => {
      if (state === AsyncState.LOADING) {
        return <MoreLoadingItem key={'moreItem'} />
      } else if (state === AsyncState.FAIL) {
        return (
          <MoreErrorItem
            onRefresh={() => {
              viewModel.loadMoreProducts()
            }}
            key={'MoreErrorItem'}
          />
        )
      } else {
        return <div key={'noItem'} />
      }
    }

    const handleSortClick = useCallback((sortType: ProductSort) => {
        viewModel.setSort(sortType);
        setSortOpen(false);
    }, []);

	return(
		<div className={styles.pageRoot}>
			<div className={styles.headerContentContainer}>
				<ProductsHeaderBlock
					viewModel={viewModel}
					onClickFilter={() => {
						navigate('filters')
					}}
        />
			</div>

            <div className={styles.sortingWrap}>
                <button
                    className={styles.sortingButton}
                    onClick={() => setSortOpen(true)}
                >
                    {sortLabels[viewModel.sort]}
                    <img src={sortIcons[viewModel.sort]}/>
                </button>
                <button
                    className={styles.hideIncomeButton}
                    onClick={() => viewModel.toggleCompensations()}
                >
                    <img src={viewModel.state.showCompensation ? hideBalanceIcon : showBalanceIcon}/>
                    {viewModel.state.showCompensation ? `Скрыть доход` : `Показать доход`}
                </button>
            </div>

            <CollectionView
                id={`products_list`}
                orientation={Orientation.VERTICAL}
                items={viewModel.state.products.data?.products ?? []}
                mapItem={(product: Product) => (
                    <ProductItem
                        key={product.id}
                        product={product}
                        showCompensation={viewModel.state.showCompensation}
                        onHideClick={() => viewModel.toggleCompensations()}
                        onClick={product => navigate(`products/${product.id}`)}
                    />
                )}
                loadMore={() => {
                    viewModel.loadMoreProducts()
                }}
                moreItem={() => moreItem(viewModel.state.loadMore)}
                containerStyle={{ paddingTop: '0px', paddingBottom: '0px'}}
            />

            {isSortOpen && (
                <BottomMenu close={() => setSortOpen(false)}>
                    <div
                        className={styles.sortItem}
                        onClick={() => handleSortClick(ProductSort.PRICE_ASC)}
                    >
                        {`Cначала дешевые`}
                        {viewModel.sort === ProductSort.PRICE_ASC && <img className={styles.sortItemActiveIcon} src={currentSortIcon}/>}
                    </div>
                    <div
                        className={styles.sortItem}
                        onClick={() => handleSortClick(ProductSort.PRICE_DESC)}
                    >
                        {`Cначала дорогие`}
                        {viewModel.sort === ProductSort.PRICE_DESC && <img className={styles.sortItemActiveIcon} src={currentSortIcon}/>}
                    </div>
                    <div
                        className={styles.sortItem}
                        onClick={() => handleSortClick(ProductSort.NONE)}
                    >
                        {`Без сортировки`}
                        {viewModel.sort === ProductSort.NONE && <img className={styles.sortItemActiveIcon} src={currentSortIcon}/>}
                    </div>
                </BottomMenu>
            )}
        </div>
    )
}

export default observer(ProductsScreen)
