export interface Async<T> {
    state: AsyncState
    error?: any
    data: T|null
    isLoading: boolean
}

export enum AsyncState {
    NONE,
    SUCCESS,
    FAIL,
    LOADING
}

export function success<T>(data: T): Async<T> {
    return {
        state: AsyncState.SUCCESS,
        error: null,
        data: data,
        isLoading: false
    }
}

export function loading<T>(): Async<T> {
    return {
        state: AsyncState.LOADING,
        error: null,
        data: null,
        isLoading: true
    }
}

export function initial<T>(): Async<T> {
    return {
        state: AsyncState.NONE,
        error: null,
        data: null,
        isLoading: false
    }
}

export function failure<T>(error?:any): Async<T> {
    return {
        state: AsyncState.FAIL,
        error: error,
        data: null,
        isLoading: false
    }
}
