import {Async, failure, initial, loading, success} from "../../data/entity/Async";
import {makeAutoObservable} from "mobx";
import {repository} from "../../data/repo/Repository";

interface State {
    codeRequest: Async<boolean>
    resetRequest: Async<boolean>
    email: string
}

export class ResetViewModel {
    codeRequest: Async<boolean> = initial()
    resetRequest: Async<boolean> = initial()
    private email: string = ''

    constructor() {
        makeAutoObservable(this)
    }

    get state(): State {
        return {
            codeRequest: this.codeRequest,
            resetRequest: this.resetRequest,
            email: this.email
        }
    }

    getCode(email: string) {
        this.codeRequest = loading()
        repository.resetGetCode(email)
            .then(() => {
                this.email = email
                this.codeRequest = success(true)
            })
            .catch((e) => {
                this.codeRequest = failure(e)
            })
    }

    reset(code: string, password: string) {
        this.resetRequest = loading()
        repository.reset(this.email, password, code)
            .then(() => {
                this.resetRequest = success(true)
            })
            .catch((e) => {
                this.resetRequest = failure(e)
            })
    }
}
