import {Async, failure, loading, success} from "../../../data/entity/Async";
import {ClickInfo} from "../../../data/network/dto/ClickInfo";
import {computed, makeObservable, observable} from "mobx";
import {repository} from "../../../data/repo/Repository";

interface State {
    clicks: Async<ClickInfo[]>
}

export class StatisticDetailsViewModel {
    private offerId = ''
    private start = ''
    private end = ''
    clicks: Async<ClickInfo[]> = loading()

    constructor(offerId: string, start: string, end: string) {
        this.offerId = offerId;
        this.start = start;
        this.end = end;
        makeObservable(this,{
            clicks: observable,
            state: computed
        })
        this.loadClicks()
    }

    get state(): State {
        return {
            clicks: this.clicks
        }
    }

    private loadClicks() {
        repository.getClicksStatistic(this.offerId, this.start, this.end)
            .then((clicks) => this.clicks = success(clicks))
            .catch((e) => this.clicks = failure(e))
    }
}
