import {Async, failure, loading, success} from "../../../data/entity/Async";
import {TopSeller} from "../../../data/network/dto/TopSeller";
import {computed, makeAutoObservable, makeObservable, observable} from "mobx";
import {repository} from "../../../data/repo/Repository";
import {timeUtil} from "../../../data/utils/TimeUtil";

interface State {
    sellers: Async<TopSeller[]>
}

export class TopSellersViewModel {
    private sellers: Async<TopSeller[]> = loading()

    constructor() {
        makeAutoObservable(this)
        this.loadSellers()
    }

    get state(): State {
        return {
            sellers: this.sellers
        }
    }

    private loadSellers() {
        repository.getTopSellers(timeUtil.currentYearNumber(), timeUtil.currentMonthNumber())
            .then((sellers) => this.sellers = success(sellers))
            .catch((e) => this.sellers = failure(e))
    }
}
