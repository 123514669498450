import style from './plan_view.module.css'

interface Props {
    plan: number
    count: number
}
export const SuccessPlanView = ({plan, count}:Props) => {

    return <div className={style.container}>
        <p className={style.title}>Выполнение плана</p>
        <div className={style.greenContainer}/>
        <div className={style.line}/>
        <p className={style.countStart}>0</p>
        <p className={style.countSuccess}>{plan}</p>
        <p className={style.countEnd}>{count}</p>
    </div>
}

export const NotSuccessPlanView = ({plan, count}:Props) => {

    return <div className={style.container}>
        <p className={style.title}>Выполнение плана</p>
        <div className={style.greyContainer}>
            <div className={style.redContainer}/>
        </div>
        <p className={style.countStart}>0</p>
        <p className={style.countError}>{count}</p>
        <p className={style.countEnd}>{plan}</p>
    </div>
}
