import {QrData} from "../../../../data/network/dto/PaymentDto";
import styles from "./product_feedback.module.css";
import {SimpleNavBar} from "../../../../components/navbar/SimpleNavBar";
import {useNavigate} from "react-router-dom";
import ButtonAccent from "../../../../components/button/ButtonAccent";
import {share} from "../../../RootScreen";
import ButtonSecondary from "../../../../components/button/ButtonSecondary";

interface Props {
    feedback?: QrData
}

export const ProductFeedbackScreen = ({feedback}: Props) => {

    let navigate = useNavigate()

    return <div className={styles.pageRoot}>
        <SimpleNavBar title='Форма обратной связи'/>

        {feedback
            && <div className={styles.contentContainer}>
                <p className={styles.title}>Форма обратной связи</p>
                <img src={feedback.qr} className={styles.qrCode}/>
                <p className={styles.subtitle}>Помоги клиенту отсканировать QR-код на форму обратной связи</p>
            </div>}
        {feedback
            && <div className={styles.buttonsContainer}>
                <ButtonAccent
                    title='Поделиться'
                    onClick={() => {
                        share(feedback.url)
                    }}
                    isLoading={false}
                    isEnabled={true}/>

                <ButtonSecondary
                    title='Закрыть'
                    onClick={() => {
                        navigate(-1)
                    }}/>
            </div>}
    </div>
}
