import {FC, ReactNode} from "react";
import {observer} from "mobx-react";
import styles from "./bottom_menu.module.css";
import RelativePortal from "../relative_portal/RelativePortal";

interface Props {
    children: ReactNode
    close: () => void
}

const BottomMenu: FC<Props> = (props: Props) => {
    return (
        <RelativePortal>
            <div className={styles.bottomMenuWrap} onClick={props.close}>
                <div className={styles.bottomMenu}>{props.children}</div>
            </div>
        </RelativePortal>
    );
};

export default observer(BottomMenu);