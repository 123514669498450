import {FC, memo, ReactNode} from "react";
import {createPortal} from "react-dom";

interface Props {
    children: ReactNode
}

const RelativePortal: FC<Props> = (props: Props) => {
    const rootEl: HTMLElement | null = document.getElementById(`root`);
    if (!!rootEl) {
        return createPortal(props.children, rootEl);
    }
    return null;
};

export default memo(RelativePortal);