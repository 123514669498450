import {Route, Routes, useLocation, useNavigate, useParams} from "react-router-dom";
import {useEffect, useMemo} from "react";
import {PromocodeViewModel} from "./PromocodeViewModel";
import styles from './sell_promocode.module.css'
import SellPromocodeScreen from "./SellPromocodeScreen";
import {observer} from "mobx-react";
import {reaction} from "mobx";
import {AsyncState} from "../../../../data/entity/Async";
import {OrderErrorOfferRefunded, OrderErrorRevoked, OrderErrorScreen} from "../error/OrderErrorScreen";
import {PlainPromocodeScreen} from "../plain/PlainPromocodeScreen";

const SellPromocodeFlowScreen = () => {

    let navigate = useNavigate()
    let location = useLocation()
    let {orderId} = useParams()
    let viewModel = useMemo(() => new PromocodeViewModel(orderId!), [])

    useEffect(() => {
        return function cleanup() {
            viewModel.deInit()
        }
    }, [])

    useEffect(() => reaction(() => viewModel.order,
        order => {
            if (order.state === AsyncState.SUCCESS) {
                let status = order.data?.status!
                if ((status === 'in_process' || status === 'new') && !location.pathname.endsWith(orderId!)) {
                    navigate(-1)
                } else if (status === 'success' && !location.pathname.endsWith('success')) {
                    navigate('success', {
                        state: {
                            code: order.data?.promo_code?.code,
                            id: order.data?.promo_code?.id
                        },
                        replace: true
                    })
                } else if (status === 'revoked' || status === 'error') {
                    navigate('error', {
                        state: {
                            type: OrderErrorRevoked
                        },
                        replace: true
                    })
                } else if (status === 'refunded') {
                    navigate('error', {
                        state: {
                            type: OrderErrorOfferRefunded
                        },
                        replace: true
                    })
                }
            } else if (order.state === AsyncState.FAIL) {

            }
        }), []);

    return <div className={styles.pageRoot}>
        <Routes>
            <Route index element={
                <SellPromocodeScreen viewModel={viewModel}/>
            }/>
            <Route path='error' element={
                <OrderErrorScreen/>
            }/>
            <Route path='success' element={
                <PlainPromocodeScreen/>
            }/>
        </Routes>
    </div>
}

export default observer(SellPromocodeFlowScreen)
