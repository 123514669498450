import styles from "./tag_item.module.css"
import {Tag} from "../../data/entity/Tag";

interface Props {
    tag: Tag,
    isSelectedTag: boolean,
    onTagClick: (tag: Tag) => void
}

export const TagItem = ({tag, isSelectedTag, onTagClick}: Props) => {
    return <li className={styles.container}>
        <div onClick={() => onTagClick(tag)}>
            <p className={isSelectedTag ? styles.titleActive : styles.titleInactive}>
                {tag.title}
                {!!tag.count && (
                    <>
                        {`\u00A0`}
                        {tag.count}
                    </>
                )}
            </p>
        </div>
    </li>
}
