import {FilterInfo} from "../../data/entity/FilterInfo";
import {eventBus} from "../../data/utils/EventBus";
import {ApplyFilter} from "../../data/utils/Events";
import {PlatformsInfo} from "../../data/entity/PlatformsInfo";
import {Async, failure, loading, success} from "../../data/entity/Async";
import {repository} from "../../data/repo/Repository";
import {makeAutoObservable} from "mobx";

interface State {
    filterInfo: FilterInfo
    platformsInfo: Async<PlatformsInfo>
}
export class FilterViewModel {
    private filterInfo: FilterInfo = {
        isPromo: null,
        platforms: null,
        publishers: null,
        activationPlatforms: null,
        releaseYears: null
    }
    private platformsInfo: Async<PlatformsInfo> = loading()

    constructor(filterInfo: FilterInfo) {
        this.filterInfo = filterInfo
        makeAutoObservable(this)
        this.loadPlatforms()
    }

    get state(): State {
        return {
            filterInfo: this.filterInfo,
            platformsInfo: this.platformsInfo
        }
    }

    applyFilter() {
        eventBus.post(ApplyFilter, this.filterInfo)
    }

    setFilterInfo(filterInfo: FilterInfo) {
        this.filterInfo = filterInfo
    }

    reset() {
        this.filterInfo = {
            isPromo: null,
            platforms: null,
            publishers: null,
            activationPlatforms: null,
            releaseYears: null
        }
    }

    setPlatforms(platforms: string[]) {
        if (platforms.length === 0) {
            this.filterInfo = {...this.filterInfo, platforms : null}
        } else {

        } this.filterInfo = {...this.filterInfo, platforms : platforms}
    }

    setYears(years: string[]) {
        if (years.length === 0) {
            this.filterInfo = {...this.filterInfo, releaseYears : null}
        } else {

        } this.filterInfo = {...this.filterInfo, releaseYears : years}
    }

    setPublishers(publishers: string[]) {
        if (publishers.length === 0) {
            this.filterInfo = {...this.filterInfo, publishers : null}
        } else {

        } this.filterInfo = {...this.filterInfo, publishers : publishers}
    }

    setPlatformsActivation(platformsActivation: string[]) {
        if (platformsActivation.length === 0) {
            this.filterInfo = {...this.filterInfo, activationPlatforms : null}
        } else {

        } this.filterInfo = {...this.filterInfo, activationPlatforms : platformsActivation}
    }


    setPromo(isPromo: boolean) {
        if (isPromo) {
            this.filterInfo = {...this.filterInfo, isPromo : true}
        } else {
            this.filterInfo = {...this.filterInfo, isPromo : null}
        }
    }

    private loadPlatforms() {
        repository.getProductPlatforms()
            .then((platforms) => this.platformsInfo = success(platforms))
            .catch((e) => this.platformsInfo = failure(e))
    }
}
