import {useLocation, useNavigate} from "react-router-dom";
import styles from './plain_promocode.module.css'
import {SimpleNavBar} from "../../../../components/navbar/SimpleNavBar";
import ButtonAccent from "../../../../components/button/ButtonAccent";
import {share} from "../../../RootScreen";
import ButtonSecondary from "../../../../components/button/ButtonSecondary";
import QRCode from "react-qr-code";
import {useCallback, useMemo} from "react";
import {PlainPromocodeViewModel} from "./PlainPromocodeViewModel";

export const PlainPromocodeScreen = () => {

    let navigate = useNavigate()
    let location = useLocation()

    let viewModel = useMemo(() => new PlainPromocodeViewModel(location.state.id), [])

    const handleClose = useCallback(() => {
        if (window.confirm(`Убедись, что передал Промокод клиенту. При закрытии оффера он больше не будет доступен`)) {
            navigate(-1)
        }
    }, []);

    return(
        <div className={styles.pageRoot}>
            <SimpleNavBar title={`Промокод`} close={handleClose} />

            <div className={styles.contentContainer}>
                <p className={styles.title}>Промокод</p>
                <QRCode
                    className={styles.qr}
                    size={190}
                    value={location.state.code}
                    viewBox={`0 0 190 190`}
                />
                <p className={styles.number}>{location.state.code}</p>
                <p className={styles.subtitle}>Передай Промокод клиенту и помоги активировать</p>
            </div>

            <div className={styles.buttonsContainer}>
                <ButtonAccent
                    title='Поделиться'
                    onClick={() => {
                        share(location.state.code)
                    }}
                    isLoading={false}
                    isEnabled={true}/>

                <ButtonSecondary
                    title='Закрыть'
                    onClick={handleClose}/>
            </div>
        </div>
    )
}
