import styles from "./button_accent.module.css";
import {CSSProperties} from "react";

interface Props {
    title: string,
    onClick: () => void,
    isLoading: boolean,
    isEnabled: boolean,
    buttonStyle?: CSSProperties
}

const ButtonAccent = ({onClick, title, buttonStyle, isEnabled, isLoading}: Props) => {
    return (
        <div
            className={isEnabled ? styles.container : styles.disabledContainer}
            style={buttonStyle}
            onClick={() => {
                if (isEnabled && !isLoading) {
                    onClick()
                }
            }}>
            {isLoading ?
                (<span className={styles.loader}/>) :
                (<p className={styles.text}>{title}</p>)
            }
        </div>
    );
}

export default ButtonAccent;
