import {ResetViewModel} from "./ResetViewModel";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import LoginHeader from "../../components/login_header/LoginHeader";
import styles from "./reset.module.css";
import {InputText} from "../../components/input/InputText";
import ButtonAccent from "../../components/button/ButtonAccent";
import {observer} from "mobx-react";


interface Props {
    viewModel: ResetViewModel
}
const ResetScreen = ({viewModel}: Props) => {
    const navigate = useNavigate()
    const [email, setEmail] = useState(viewModel.state.email);

    return <div>
        <LoginHeader onBackClick={() => navigate(-1)} title={`Восстановление\nпароля`}/>
        <div className={styles.container}>
            <p className={styles.description}>{'Введите e-mail пользователя, для которого необходимо восстановить пароль'}</p>
            <InputText
                placeholder='Ваш Email'
                onChange={text => setEmail(text)}/>

            <ButtonAccent
                buttonStyle={{marginTop: 32}}
                onClick={() => viewModel.getCode(email)}
                title={'Далее'}
                isLoading={viewModel.state.codeRequest.isLoading}
                isEnabled={Boolean(email.length)}/>

            <p className={styles.legal}>{'Пожалуйста, введите действующий адрес электронной почты. Это необходимо для безопасного вывода денег и других финансовых операций в будущем'}</p>
        </div>
    </div>
}

export default observer(ResetScreen)
