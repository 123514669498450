import { FilterInfo } from '../entity/FilterInfo'
import { ProductSort } from '../entity/ProductSort'
import {credentialManager} from "../repo/CredentialManager";
import {eventBus} from "../utils/EventBus";
import {LogoutEvent} from "../utils/Events";
import { Offer } from './dto/OfferDto'
import {CreatePaymentInfo, ProductOrder, PromoCode} from './dto/PaymentDto'
import {Platform, Product, ProductFull} from './dto/ProductDto'
import { ProductReferralLinkResponse } from './dto/ProductReferralLinkResponse'
import { ReferralLink } from './dto/ReferralLink'
import { TagDto } from './dto/TagDto'
import { User } from './dto/UserDto'
import {StatisticOffer} from "./dto/StatisticOffer";
import {ClickInfo} from "./dto/ClickInfo";
import {TopSeller} from "./dto/TopSeller";
import {RegisterActivateResponse} from "./dto/RegisterActivateResponse";

const uriBase = /*process.env.NODE_ENV === 'production'
    ? 'https://appsell.me'
    : */'https://demo.appsell.me'

export const network = {
    getUser: async () => api.get<User>(`/api/users/current`, null),

    login: async (email: string, password: string) => api.post<LoginResponse>(
        '/api/auth/login',
        {
            email: email,
            password: password
        },
        null
    ),

    getOffers: async (count: number, offset: number, tag: string | null, query: string | null, abortSignal: AbortSignal | undefined = undefined) => {
        const params = new Map<string, string>()
        params.set("limit", `${count}`)
        params.set("offset", `${offset}`)
        if (tag) {
            params.set("tags", `${tag}`)
        }
        if (query) {
            params.set("query", `${query}`)
        }
        return api.get<Offer[]>(
            "/api/employees/offers",
            params
        )
    },

    getProducts: async (count: number, offset: number, tag: string | null, query: string | null, filterInfo: FilterInfo, sort: ProductSort, abortSignal: AbortSignal | undefined = undefined) => {
        const params = new Map<string, string>()
        params.set("limit", `${count}`)
        params.set("offset", `${offset}`)
        if (tag) {
            params.set("tags", `${tag}`)
        }
        if (query) {
            params.set("query", `${query}`)
        }
        if (filterInfo.platforms?.length) {
            params.set("filter[platforms]", filterInfo.platforms?.join(','))
        }
        if (filterInfo.publishers?.length) {
            params.set("filter[publishers]", filterInfo.publishers?.join(','))
        }
        if (filterInfo.activationPlatforms?.length) {
            params.set("filter[activation_platforms]", filterInfo.activationPlatforms?.join(','))
        }
        if (filterInfo.releaseYears?.length) {
            params.set("filter[release_years]", filterInfo.releaseYears?.join(','))
        }
        if (filterInfo.isPromo === true) {
            params.set("filter[is_promo]", "true")
        }
        if (sort === ProductSort.PRICE_ASC) {
            params.set("sort[price]", "asc")
        } else if (sort === ProductSort.PRICE_DESC) {
            params.set("sort[price]", "desc")
        }
        return api.get<Product[]>(
            "/api/employees/products",
            params
        )
    },

    getOffer: async (offerId: string) => {
        return api.get<Offer[]>(
            `/api/employees/offers/${offerId}`,
            null
        )
    },

    getOfferGroup: async (offerGroupId: string) => {
        return api.get<Offer[]>(
            `/api/employees/offers/${offerGroupId}/children`,
            null
        )
    },

    getProductReferralLink: async (productId: string) => {
        return api.post<ProductReferralLinkResponse>(
            `/api/employees/products/${productId}/referral-links`,
            null,
            null
        )
    },

    getProduct: async (productId: string) => {
        return api.get<ProductFull>(
            `/api/employees/products/${productId}`,
            null
        )
    },

    createProductOrder: async (productId: string) => {
        return api.post<ProductOrder>(
            `/api/payment-orders`,
            {
                offer_id: productId
            },
            null
        )
    },

    getProductOrderStatus: async(orderId: string, abortSignal: AbortSignal | undefined = undefined) => {
        return api.get<ProductOrder>(
            `/api/payment-orders/${orderId}`,
            null,
            abortSignal
        )
    },

    getTags: async () => api.get<TagDto[]>(
        "/api/employees/offers/tags",
        null
    ),

    getProductTags: async () => api.get<TagDto[]>(
        "/api/employees/products/tags",
        null
    ),

    getReferralLink: async (offerId: string) => api.post<ReferralLink>(
        `/api/employees/offers/${offerId}/referral-links`,
        null,
        null
    ),

    getFollowInfo: async (linkId: string) => api.get<FollowInfo>(
        `/api/referral-links/${linkId}/follow-info`,
        null,
    ),

    sendKeyAgain: async (orderId: string) => api.get(
        `/api/payment-orders/${orderId}/receipt`,
        null
    ),
    requestPromoCode: async (offerId: string, executionId: string, token: string | null = null) => api.post<PromoCode>(
        `/api/employees/offers/${offerId}/promo-codes/lock`,
        {
            execution_id: executionId,
            token
        },
        null
    ),
    markPromocodeAsShown: async (promocodeId: string) => api.post(
        `/api/employees/offers/promo-codes/${promocodeId}/mark-as-shown`,
        null,
        null
    ),

    getPaymentStatus: async (orderId: string, abortSignal: AbortSignal | undefined = undefined) => api.get<CreatePaymentInfo>(
        `/api/payment-orders/${orderId}`,
        null,
        abortSignal
    ),

    createPayment: async (offerId: string, executionId: string | null) => api.post<CreatePaymentInfo>(
        `/api/payment-orders`,
        {
            offer_id: offerId,
            execution_id: executionId
        },
        null
    ),

    getProductPlatforms: async () => api.get<Platform[]>(
        `/api/employees/products/platforms`,
        null
    ),

    getProductYears: async () => api.get<Platform[]>(
        `/api/employees/products/release-years`,
        null
    ),

    getProductPublishers: async () => api.get<Platform[]>(
        `/api/employees/products/publishers`,
        null
    ),

    getProductPlatformsActivation: async () => api.get<Platform[]>(
        `/api/employees/products/activation-platforms`,
        null
    ),

    getStatistic: async  (start: string, end: string, abortSignal: AbortSignal | undefined) => {
        const params = new Map<string, string>()
        params.set('start_date', start)
        params.set('end_date', end)
        return api.get<StatisticOffer[]>(
            '/api/employees/offers/statistics-v2',
            params,
            abortSignal
        )
    },

    getClicksStatistic: async (offerId: string, start: string, end: string) => {
        const params = new Map<string, string>()
        params.set('start_date', start)
        params.set('end_date', end)
        return api.get<ClickInfo[]>(
            `/api/employees/offers/${offerId}/clicks/statistics/`,
            params,
        )
    },

    getTopSellers: async (yearNumber: number, monthNumber: number)=> {
        const params = new Map<string, string>()
        params.set('year', `${yearNumber}`)
        params.set('month', `${monthNumber}`)
        return api.get<TopSeller[]>(
            `/api/employees/rating/monthly`,
            params,
        )
    },
    register: async (company: string, email: string, password: string)=> api.post(
        `/api/registration/employees`,
        {
            company_id: company,
            email: email,
            password: password,
        },
        null
    ),

    activate: async (email: string, code: string, employeeNumber: string)=> api.post<RegisterActivateResponse>(
        `/api/registration/employees/activate`,
        {
            email: email,
            code: code,
            employee_number: employeeNumber
        },
        null
    ),

    reset: async (email: string, password: string, code: string) => api.put(
        `/api/users/password`,
        {
            email: email,
            new_password: password,
            code: code,
        },
        null
    ),

    resetGetCode: async (email: String) => api.post(
        `/api/users/password/recovery-codes`,
        {
            email: email,
        },
        null
    ),

    changePassword: async (oldPassword: string, newPassword: string) => api.put<LoginResponse>(
        `/api/users/current/password`,
        {
            password: oldPassword,
            new_password: newPassword,
        },
        null
    ),
}

const api = {
    get: async <TResponse>(url: string, params: Map<string, string> | null, abortSignal: AbortSignal | undefined = undefined) =>
        request<TResponse>(`${uriBase}${url}`, 'GET', params, null, abortSignal),

    post: async <TResponse>(url: string, body: object | null, params: Map<string, string> | null, abortSignal: AbortSignal | undefined = undefined) =>
        request<TResponse>(
            `${uriBase}${url}`, 'POST', params, body, abortSignal),

    put: async <TResponse>(url: string, body: object | null, params: Map<string, string> | null, abortSignal: AbortSignal | undefined = undefined) =>
        request<TResponse>(
            `${uriBase}${url}`, 'PUT', params, body, abortSignal),
}

async function request<TResponse>(
    url: string,
    method: string,
    params: Map<string, string> | null,
    body: object | null,
    abortSignal: AbortSignal | undefined
): Promise<TResponse> {
    let queries = ''
    if (params !== null) {
        queries = '?'
        queries += Array.from(params.entries())
            .map((value) => encodeURIComponent(value[0]) + '=' + encodeURIComponent(value[1]))
            .join('&')
    }
    const token = credentialManager.getToken()
    const response = await fetch(
        `${url}${queries}`,
        {
            method: method,
            headers: {
                Accept: 'application/json',
                ...(body !== null && {'Content-Type': 'application/json'}),
                ...(token !== null && {Authorization: `Bearer ${token}`})
            },
            signal: abortSignal,
            ...(body !== null && {body: JSON.stringify(body)})
        }
    ).then((response) => {
        if (!response.ok) {
            if ((response.status === 401 ||response.status === 403) && credentialManager.isLogged()) {
                eventBus.post(LogoutEvent)
            }
            return response.json().then((baseResp) => {
                baseResp.status = response.status;
                throw baseResp;
            })
        } else {
            return response.json()
                .catch((e) => {
                    console.log(e)
                    return response
                })
        }
    })
    return await response;
}
