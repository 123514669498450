import back_icon from '../../images/icons/ic_20_back.svg'
import styles from './login_header.module.css'

const LoginHeader = ({onBackClick, title}: {onBackClick: () => void, title: string}) => {
  return (
      <div className={styles.container}>
            <img
                src={back_icon}
                className={styles.icon}
                alt='back'
                onClick={onBackClick}
            />
          <p className={styles.title}>{title}</p>
      </div>
  )
}

export default LoginHeader
