import {CompensationInfo, Offer} from '../../data/network/dto/OfferDto';
import {useQuery} from "react-query";
import styles from "../offer/offer_item.module.css";
import {useCallback, useState} from "react";
import {repository} from "../../data/repo/Repository";
import {useNavigate} from "react-router-dom";
import {calculateCompensations, hasAdditionalCompensations} from "../offer/OfferItem";

import expanderPlusIcon from "../../images/icons/ic_16_plus.svg";
import expanderMinusIcon from "../../images/icons/ic_16_minus.svg";

interface Props {
    offer: Offer
}

export const OfferGroup = ({offer}: Props) => {
    let navigate = useNavigate();
    const expandedGroups = JSON.parse(sessionStorage.getItem(`groups-expanded`) ?? `[]`);
    const [isExpanded, setExpanded] = useState<boolean>(!!expandedGroups.find((item: string): boolean => item === offer.id));

    const childrenRes = useQuery(
        [`group-children`, offer.id],
        () => repository.getOfferGroup(offer.id),
        {
            enabled: isExpanded
        }
    );
    const children = childrenRes.data;

    const handleToggleExpandGroup = useCallback(async () => {
        if (!isExpanded) {
            setExpanded(true);
            sessionStorage.setItem(`groups-expanded`, JSON.stringify([...expandedGroups, offer.id]));
        } else {
            setExpanded(false);
            sessionStorage.setItem(`groups-expanded`, JSON.stringify(expandedGroups.filter((item: string): boolean => item !== offer.id)));
        }
    }, [expandedGroups, isExpanded, offer.id]);

    return (
        <li>
            <div
                className={styles.container}
                onClick={handleToggleExpandGroup}
            >
                {offer.image ? <img src={offer.image} className={styles.logo} alt={``}/> :
                    <div className={styles.logo}/>}

                <div className={styles.content}>
                    <div className={styles.textContainer}>
                        <p className={styles.title}>{offer.title}</p>
                        <p className={styles.subtitle}>{offer.short_description}</p>
                    </div>

                    <img
                        className={styles.groupExpander}
                        src={isExpanded ? expanderMinusIcon : expanderPlusIcon}
                        alt={isExpanded ? `-` : `+`}
                    />
                </div>
            </div>

            {isExpanded && children?.map((child: Offer) => {
                const compensation: CompensationInfo = calculateCompensations(offer)

                return (
                    <div
                        key={child.id}
                        className={styles.childContainer}
                        onClick={() => navigate(`offers/${child.id}`)
                    }>
                        <div className={styles.childContent} key={child.id}>
                            <div className={styles.textContainer}>
                                <p className={styles.title}>{child.title}</p>
                                <p className={styles.childSubtitle}>{child.short_description}</p>
                            </div>
                            <p className={styles.price}>{
                                hasAdditionalCompensations(offer)
                                    ? `${compensation.price} ${compensation.currency}\n + доп. комп.`
                                    : `${compensation.price} ${compensation.currency}`
                            }</p>
                        </div>
                    </div>
                )
            })}
        </li>
    );
}